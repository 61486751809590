import React, { useState, memo, useEffect } from "react";
import Api from "../../../../services";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import xbutton from "../../../../assets/xbutton.png";
import minus from '../../../../assets/minus.svg';
import plus from '../../../../assets/plus.svg';
import { useStore } from "../../../../context";
import blackdram from "../../../../assets/black-price.svg";

const DesktopItem = props => {
    const { item, addToBag, removeFromWishlist } = props;
    const [productCount, setProductCount] = useState(1);
    const { updateProductInWishlist, tr, text, setText} = useStore();
   

    const addToBagHandler = id => {
        addToBag(id, productCount, item.allow_quantity);
        removeFromWishlist(id);
    }


    const changeSizeHandler = e => {
        const newItemId = e.target.value;
        updateProductInWishlist(item.id, newItemId);
        setProductCount(1)
    }


    const productCounterHandler = (param) => {
        switch (param) {
            case '-':
                setProductCount(count => count > 1 ? --count : count)
                break;
            case '+':
                setProductCount(count => ++count)
                break;
        }
    };


    return (
        <tr>
            <td>
                <div className="cart_item_image_title_container">
                    <div className="cart_item_image">
                        {item.images && <img alt="cart" src={`${Api.imgUrl}${item.images?.[0]?.image}`} />}
                    </div>
                    <div className="cart_item_title">
                        {item.title}
                    </div>
                </div>
            </td>
            <td>
                {item.sizes.length > 0 &&
                    <div className="cart_mobile_des_quality cart_item_quality" id="td-mrg">
                        <FormControl>
                            <Select
                                native
                                value={Number(item.id)}
                                onChange={changeSizeHandler}
                            >
                                {item.sizes.map(sizeItem => <option value={Number(sizeItem.id)}>{sizeItem.size.size}</option>)}
                            </Select>
                        </FormControl>
                    </div>}
            </td>
            <td>
                {item?.allow_quantity && !item?.out_of_stock &&
                    <div className="product_count_container ">
                        <div className="product_count_button" onClick={() => productCounterHandler('-')}>
                        <img alt="minus" src={minus} />
                        </div>
                        <input type="text" readOnly className="product_count_input" value={productCount} />
                        <div className="product_count_button" onClick={() => productCounterHandler('+')}>
                        <img alt="plus" src={plus} />
                        </div>
                    </div>}
            </td>
            <td>
                <div className="cart_item_price">
                    {item.cost > 0 ? item.cost.toLocaleString('de-DE') : tr.free}
                    {item?.cost > 0 && <img src={blackdram} />}
                </div>
            </td>
            <td>
                <div className="wishlist_item_cart_button_container">
                    {!item?.out_of_stock &&
                        <div onMouseOver={() => setText(true)} onMouseLeave={() => setText(false)} className="wishlis_item_button"
                        // onClick={() => addToBagHandler(item.id)}
                        >
                            {text ?
                                tr.addToBagHover
                                :
                                tr.addToBag
                            }
                        </div>}
                    <div className="x_button" onClick={() => removeFromWishlist(item.id)}>
                        <img alt="close" src={xbutton} />
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default memo(DesktopItem);