import React, { memo, useState, useEffect } from "react";
import "./product-description.css";
import heart from "../../../../../assets/heart.svg";
import bluecheck from "../../../../../assets/bluecheck.png";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import freedelivery from "../../../../../assets/freedelivery.png";
import OutOfStock from "../out-of-stock";
import { useStore } from '../../../../../context';
import selectedHeart from '../../../../../assets/heart_selected.svg';
import Api from "../../../../../services";
import minus from '../../../../../assets/minus.svg';
import plus from '../../../../../assets/plus.svg';
import dram from '../../../../../assets/amd.svg';
import greyprice from '../../../../../assets/greyprice.svg';
import redprice from '../../../../../assets/reddram.svg';



const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const ProductDescription = props => {
    const { product, features, sizes, sizesHandler, shipping } = props;
    const [productCount, setProductCount] = useState(1);
    const { addToBag, addToWishlist, wishlist, removeFromWishlist, tr, setText, text } = useStore();
    const item = props.item;


    useEffect(() => {
        setProductCount(1);
    }, [product]);

    const productCounterHandler = (param) => {
        switch (param) {
            case '-':
                setProductCount(count => count > 1 ? --count : count)
                break;
            case '+':
                setProductCount(count => ++count)
                break;
        }
    };

    const addToBagHandler = () => {
        addToBag(Number(product.id), Number(productCount), product.allow_quantity);
    }

    return (
        <div className="product_description">
            <div className="product_description_title">
                {product?.title}
            </div>
            <div className="product_des_text" dangerouslySetInnerHTML={{ __html: product?.description }}>
            </div>
            {features?.length > 0 &&
                <div className="package_container">
                    <div className="product_packages_title">
                        {tr.packageInclude}
                    </div>
                    {features?.map(feature =>
                        <div className="package_items">
                            <div className="package_item_icon">
                                <img alt="Braind Shop" src={bluecheck} />
                            </div>
                            <div className="package_item_des">
                                {feature.title && <div className="package_item_title">
                                    {feature.title}
                                </div>}
                                {feature.description && <div className="package_item_text">
                                    {feature.description}
                                </div>}
                            </div>
                        </div>
                    )}
                </div>}
            <div className="product_details_price">
                <span className="product-discount">{product?.discount > 0 && <span className="line-rel">{product?.price.toLocaleString('de-DE')}<div className="line"></div><img alt="Braind Shop" src={greyprice} /></span>}</span>
                <span className={product?.discount > 0 ? "price" : null} >
                    {console.log(product?.cost)}
                    {product?.cost > 0 ? `${product.cost.toLocaleString('de-DE')}` : tr.free} {product?.cost > 0 && <img alt="Braind product" src={product?.discount > 0 ? redprice : dram} />}
                </span>
            </div>
            <div className="product_sizes_container">
                {sizes && sizes.length > 0 && <div className="products_sizes_select">
                    <FormControl>
                        <Select
                            native
                            value={product?.slug}
                            onChange={sizesHandler}
                        >
                            {sizes?.map(item => <option value={item.slug}>{item.size.size}</option>)}
                        </Select>
                    </FormControl>
                </div>}

                {product?.allow_quantity && !product?.out_of_stock &&
                    <div className="product_count_container">
                        <div className="product_count_button" onClick={() => productCounterHandler('-')}>
                        <img alt="Braind Shop minus" src={minus} />
                        </div>
                        <input type="text" readOnly className="product_count_input" value={productCount} />
                        <div className="product_count_button" onClick={() => productCounterHandler('+')}>
                        <img alt="Braind Shop plus "  src={plus} />
                        </div>
                    </div>}
                <div className="product-btn-cnt">
                    {!product?.out_of_stock && <div className="product_details_button"
                        //  onClick={addToBagHandler}
                        onMouseOver={() => setText(true)} onMouseLeave={() => setText(false)}
                    >
                        {text ?
                            tr.addToBagHover
                            :
                            tr.addToBag
                        }
                    </div>}

                    {!product?.out_of_stock && <div className="product_details_fav-button product_details_button "
                        onClick={() => { wishlist.includes(product?.id) ? removeFromWishlist(product?.id) : addToWishlist(product?.id) }}>
                        <img alt="Braind Shop product" src={wishlist.includes(product?.id) ? selectedHeart : heart} />
                    </div>}
                </div>
            </div>
            {shipping?.length > 0 && <div>
                {shipping?.map(item =>
                    <div className="free_delivery_section">
                        <img alt="delivery" src={`${Api.imgUrl}/${item.image}`} className="free_delivery_icon" />
                        <p className="free_delivery_text">
                            {item.title}
                        </p>
                    </div>)}
            </div>}
            {product?.out_of_stock && <OutOfStock productId={product.id} />}
        </div>
    );
}

export default memo(ProductDescription);