import React, {memo, useEffect, useState} from "react";
import "./product-slider.css";

const ProductSlider = props => {
    const {images, baseUrl} = props;
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        setSelected(0);
    }, [images]);

    return(
        <div className="product_slider">
            <div className="product_slider_image">
                {images && <img alt="Braind product" src={`${baseUrl}${images[selected].image}`}/>}
            </div>
            <div className="product_slider_indicators_container">
                {images?.map((item, index) =>
                    <div className="product_slider_indicator">
                        <img alt="Braind product" className={index === selected ? 'active' : null}
                             src={`${baseUrl}${item.image}`}
                             onClick={() => setSelected(index)}
                        />
                    </div>)}
            </div>
        </div>
    );
}

export default memo(ProductSlider);