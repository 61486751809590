import React, { useState, useEffect } from 'react'
import Radio from '../../../components/radio/radio'
import { useStore } from '../../../context';
import './styles.scss'



const RadioSection = ({title, action}) => {
    const [quizzes, setQuizzes] = useState(0);
    const { tr } = useStore();

    const testState = [
        {text: tr.priceOffer, _id: 1},
        {text: tr.scheduleCall, _id: 2}
    ]

    useEffect(() => {
        action(quizzes !== 0)
    }, [quizzes]);
    
    return (
        <div className="input-wrapp radio">
            <span className='subtitle'>{title}</span>
            <div className='input-box'>
                {testState.map((el, id) =>
                    <Radio
                        text={el.text}
                        onChange={() => {
                            setQuizzes(id)
                        }
                        }
                        value={quizzes === id}
                    />
                )}
            </div>
        </div>
    )
}

export default RadioSection; 