import React, { useState } from "react";
import "./style.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import Api from '../../../services';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    touchThreshold: 100,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                variableWidth: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                pauseOnFocus : true,
                dots: false,
                infinite: true,
            }
        }
    ]
};
const LayoutSlider = () => {
    const [slider, setSlider] = useState({});
    const collections = useSelector(store => store.collections)

    const SampleNextArrow = props => {
        const { className, style, onClick } = props;

        return (
            <div className={[...className, '']} onClick={onClick} ></div>
        )
    }

    const SamplePrevArrow = props => {
        const { className, style, onClick } = props;

        return (
            <div className="shop_slider_button_container" onClick={onClick} ></div>
        )



        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "green" }}
                onClick={onClick}
            />
        );
    }
    return (
        <div className="shop_page_slider">
            <Slider ref={setSlider} {...settings}>
                {collections?.map((collection) =>
                    <Link className='link-brd' to={`collection/${collection.id}`} >
                        <div>
                            <div className="shop_slider_item" style={{ backgroundImage: `url("${Api.imgUrl}/${collection.thumbnail}")` }}>
                                {collection.name}
                            </div>
                        </div>
                    </Link>)}
            </Slider>
        </div>

    );
}
export default LayoutSlider;