import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import WithHeader from '../../components/with-header-and-footer-hoc'
import Product from '../../components/shop-layout/layout-grid/_partials/products'
import Icon from '../../icon'
import Api from '../../services'
import { useDebounce } from '../../hooks/useDebounce'
import './style.scss'
import { useStore } from '../../context';

const Search = () => {
  const history = useHistory()
  const search = new URLSearchParams(useHistory().location.search).get('search')
  const [portfolio, setPortfolio] = useState();
  const [shop, setShop] = useState();
  const { setStartProjectBottom, getLang, tr } = useStore();
  const { pathname } = useLocation();
  const [loaded, setLoaded] = useState(false);


  const searchHandle = useDebounce(() => {
    Api.products.search({
      search: search ?? '',
    })
      .then(res => {
        setPortfolio(res?.data.portfolio_items)
        setShop(res.data.product_items)

        setLoaded(true)
      })
  }, 1000)

  useEffect(searchHandle, [search]);

  const searchChange = e => {
    history.push(`/search/?search=${e.target.value}`)
  }

  const isEmpty = !portfolio?.length && !shop?.length
  const isEmptyShop = !shop?.length
  const isEmptyPortfolio = !portfolio?.length

  useEffect(() => {
    if (!search || isEmpty) {
      setStartProjectBottom(false);
    } else {
      setStartProjectBottom(true);
    }
    return () => setStartProjectBottom(true);
  }, [search, isEmpty]);


  return (
    <main className='search_page'>
      <label>
        <input value={search} onChange={searchChange} placeholder='Search keywords' />
        <Icon id='search' />
      </label>

      {loaded === isEmpty ? <>
        <span data-aos='fade-up' data-aos-delay={350} className='page_title'>OOOPS no results for “keyword”</span>
        <p data-aos='fade-up' data-aos-delay={450} >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
      </> :
        <>
          { !isEmptyPortfolio && search && < span className='page_title' data-aos='fade-up' data-aos-delay={550} >{tr.portfolio}</span>}
          {search && <div className='items zoom_wrap'>
            {portfolio?.map(el =>
              <Link data-aos='fade-up' to={`${getLang()}/portfolio/${el.slug}`}>
                <div className="image_zoom">
                  <img alt="Braind Shop" src={Api.imgUrl + el.thumbnail} />
                </div>
                <span>{el.title}</span>
              </Link>
            )}
          </div>}

            {!isEmptyShop && search && <span className='page_title' data-aos='fade-up'  >{tr.shop}</span>}
          { search && <div className='items shop'>
            {shop?.map(el =>
              <Product item={el} />
            )}
          </div>}
        </>}
    </main >
  );
};

export default WithHeader(Search);