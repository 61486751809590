import React, { memo } from "react";
import "./footer.scss";
import { useSelector } from "react-redux";
import Api from '../../services';
import { useStore } from "../../context";


const Footer = () => {
    const contacts = useSelector(store => store.contacts);
    const { tr } = useStore();
    
    return (
        <div className="footer">
            <div className="footer_content">
                <div className="phone">
                    <span>{tr.giveACall}</span>
                    <a href={`tel:${contacts?.content.phone}`}>{contacts?.content.phone}</a>
                </div>
                <div className="mail">
                    <span>{tr.getInTouch}</span>
                    <a href={`mailto:${contacts?.content.email}`}>{contacts?.content.email}</a>
                </div>
                <div className="addres">
                    <span>{tr.weAreLocated}</span>
                    <span>{contacts?.content.address}</span>
                </div>
                <div className="socials">
                    {contacts?.social_links?.map(el =>
                        <a href={el.url} target='_blink'> <img alt="Braind Shop" alt={el.title} src={Api.imgUrl + el.image} /></a>
                    )

                    }
                </div>
            </div>
            <div className=" footer_side_pdng">
                <span>© 2011-{new Date().getFullYear()} Braind LLC. {tr.allRightsReserved}</span>
                <div className="footer_bottom_links">
                    <a className="ftr-links" href="/delivery"> {tr.paymentAndDelivery} </a>
                    <a className="ftr-links" href="/methods"> {tr.paymentMethods} </a>
                    <a className="ftr-links" href="/policy">{tr.privacyPolicy} </a>
                </div>
            </div>
        </div>
    );
}


export default memo(Footer);