export default {
    all: 'բոլորը',
    filter: {
        sale: 'զեղչ',
        priceHighLow: 'Գինը(բարձր - ցածր)',
        priceLowHigh: 'Գինը(ցածր - բարձր)',
    },
    sortBy: 'դասակարգել ըստ',
    cart: 'Զամբյուղ',
    goBackToShop: 'Վերադարձեք գնումներ կատարելու',
    sendMessage: 'Ուղարկել նամակ',
    send: 'Ուղարկել',
    name: 'Անուն',
    shop: 'խանութ',
    portfolio: 'Պորտֆոլիո',
    company: 'ՄԵՐ ՄԱՍԻՆ',
    start: 'սկսել նախագիծ',
    services: 'ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ',
    contact: 'ԿԱՊ',
    black_fridyan: 'ՍԵՒ ՈՒՐԲԱԹ',
    special_project: 'հատուկ նախագծեր',
    email: 'էլ հասցե',
    phone: 'հեռախոսահամար',
    text: 'Տեքստ',
    favoriteSong: 'սիրելի երգ',
    paymentAndDelivery: 'Վճարում և Առաքում',
    paymentMethods: 'Վճարման եղանակներ',
    privacyPolicy: 'Գաղտնիության քաղաքականություն',
    allRightsReserved: 'Բոլոր իրավունքները պաշտպանված են',
    addToBag: 'Ավելացնել զամբյուղ',
    addToBagHover: 'շուտով',
    popupSheduleTitle: 'Հրաշալի!',
    popupSheduleText: 'Մենք անհամբեր սպասում ենք ձեր նախագիծը քննարկելուն և աշխատանքները սկսելուն:',
    packageInclude: 'Փաթեթը ներառում է',
    free: 'Անվճար',
    delivery: 'Առաքում',
    popupOfferTitle: ' Շնորհակալություն!',
    popupOfferText: " Մենք ձեզ կուղարկենք գնային առաջարկը 1-2 աշխատանքային օրվա ընթացքում: Խնդրում ենք ստուգել ձեր սպամը և եթե այնտեղ էլ չգտնեք, գրեք մեզ masha@braind.am էլ.հասցեով:",
    similarProducts: 'Նման ապրանքներ',
    item: 'Ապրանքատեսակ',
    size: 'Չափս',
    quantity: 'Քանակ',
    price: 'Գին',
    subtotal: 'Գումարը',
    discount: 'Զեղչ',
    total: 'Ընդհանուր',
    goToPayment: 'Վճարել',
    wishlist: 'Ընտրվածներ',
    personalDetails: 'ԱՆձնական Տվյալներ',
    surname: 'Ազգանուն',
    donate: 'նվիրաբերել',
    emptyBag: 'Ձեր սայլը դատարկ է',
    addNotes: 'Ավելացնել Նշումներ',
    byClicking: 'Սեղմելով Դուք ընդունում եք մեր',
    termsAndConditions: 'ընդհանուր դրույթներր և պայմանները ',
    receiveNews: 'Ստանալ մեր նորությունները և առաջարկները',
    donateAndDownload: 'նվիրաբերել և ներբեռնել',
    getDownloadLink: 'ստանալ ներբեռնման հղումը',
    buyingFromBraind: 'braind.am-ից գնելն ապահով է .',
    yourOrderWill: 'Ձեր պատվերը մշակվելու է մեր անվտանգ սերվերներում',
    loadMore: 'Տեսնել ավելին',
    downloadPresentation: 'Ներբեռնել պրեզենտացիան',
    haveAProject: 'Ունե՞ք նախագիծ',
    getStarted: 'սկսել',
    whatWeOffer: 'Ի՞նչ ենք առաջարկում',
    ourServices: 'Մեր ծառայությունները',
    giveACall: 'Զանգահարեք մեզ',
    getInTouch: 'Կապնվել',
    weAreLocated: 'Մենք գտնվում ենք',
    client: 'Հաճախորդ',
    country: 'Երկիր',
    year: 'Տարի',
    shareWithFriends: 'Կիսվել ընկերների հետ',
    relatedProjects: 'Հարակից նախագծեր',
    allProjects: 'Բոլոր նախագծերը',
    callUs: 'Զանգահարեք մեզ',
    writeUs: 'Գրեք մեզ',
    address: 'Հասցե',
    downloadExample: 'Ներբեռնեք օրինակ',
    whatDoYouWant: 'Ի՞նչ է Ձեզ հետաքրքրում',
    priceOffer: 'Գնային առաջարկ',
    scheduleCall: 'Զանգ / հանդիպում նշանակել',
    callType: 'Զանգի նախընտրելի տարբերակ',
    selectLanguage: 'Ընտրել լեզուն',
    armenian: 'Հայերեն',
    english: 'Անգլերեն',
    russian: "Ռուսերեն",
    phoneCall: 'Հեռախոսազանգ',
    productType: 'Հետաքրքրող ծառայություն',
    wrong: 'ինչ որ բան սխալ է',
    company_details: 'Ձեր նախագծի մասին',
    company_name: 'Ընկերության անվանումը',
    tell_about_project: 'Պատմեք մեզ Ձեր նախագծի մասին ',
    attachFile: 'Կցել ֆայլ',
    personalDetails: 'Անձնական տվյալներ',
    phone_number: 'Հեռախոսահամար',
    date_and_time: 'Օրը և ժամը',
    know_about_us: 'Ինչպե՞ս եք իմացել մեր մասին',
    social_networks: 'Սոց. ցանցեր',
    recomendations: 'Ընկերներ',
    rating: 'Վարկանիշ',
    required_skills: 'Պահանջվող հմտություններ',
    about_portfolio: 'Մի քիչ պատմիր մեզ քո մասին և ուղարկիր պորտֆոլիոդ',
    benefit_pack: 'Լավ բաներից Braind-ում',
    date: 'Ամսաթիվ',
    time: 'Ժամ',
}