import React, { useEffect, useState } from "react";
import './shop-page-grid.scss';
import Icon from '../../../icon';
import Api from '../../../services';
import { Link } from "react-router-dom";
import { useStore } from '../../../context';

const ShopPageGrid = () => {
    const [data, setData] = useState()
    const [aboutData, setAboutData] = useState()
    const [items, setItems] = useState([])
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const { getLang, tr } = useStore();

    useEffect(() => {
        Api.getData.getHomePageContent()
            .then(res => setData(res?.data?.item))
        getItems();
    }, [])

    const getItems = () => {
        Api.getData.getHomePortfolioItem({
            page: page + 1
        })
            .then(res => {
                setItems(st => [...st, ...res?.data.data]);
                setPage(res?.data?.current_page);
                setLastPage(res?.data?.last_page);
            })
        Api.getData.getHomeAboutContent()
            .then(res => setAboutData(res?.data?.item))
    }
    return (
        <div className="shop_layout">
            <div className='about_content'>
                <div className="left_side">
                    <div data-aos='fade-up' data-aos-delay={300} className="title">
                        {data?.about_title && <h3>{aboutData?.about_title}</h3>}
                    </div>
                    <div className="description">
                        <span data-aos='fade-up' data-aos-delay={400} dangerouslySetInnerHTML={{ __html: aboutData?.about_text }} />
                        <a download href={`${Api.imgUrl}${aboutData?.about_file}`} data-aos='fade-up' data-aos-delay={500} target='_blink'>{tr.downloadPresentation}<Icon id='right_arrow' /></a>
                    </div>
                </div>
                <div className="right_side">
                    <Link to={`${getLang()}/brief`} data-aos='fade-up' data-aos-delay={300} className="box">
                        <span data-aos='fade-up'  >
                            {tr.haveAProject}
                        </span>
                        <h3 data-aos='fade-up'  >{tr.getStarted}</h3>
                    </Link>
                    <Link to={`${getLang()}/services`} data-aos='fade-up' data-aos-delay={300} className="box">
                        <span data-aos='fade-up'  >
                            {tr.whatWeOffer}
                            </span>
                        <h3 data-aos='fade-up'>{tr.ourServices}</h3>
                    </Link>
                </div>
            </div>
            <div className='grid_items'>
                {items?.map((el, i) =>
                    <Link data-aos='fade-up' to={`${getLang()}/portfolio/${el.slug}`} className="grid_item" key={i}>
                        <div data-aos-delay={`${i + 7}00`} data-aos-easing="ease-out " data-aos-duration="1100" className="image-box">{el.thumbnail && <img alt="products" src={Api.imgUrl + el.thumbnail} />}</div>
                        {el.title && <span>{el.title}</span>}
                    </Link>
                )}
            </div>
            {page < lastPage && <div onClick={getItems} className="load_more">
                {tr.loadMore}
            </div>}
        </div>
    );
}

export default ShopPageGrid;