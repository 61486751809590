import BaseRequestService from "./base-request-service";

class getData extends BaseRequestService {
    getSlider = () => this.getRequest('/api/site/home/slider');
    getHomePageContent = () => this.getRequest('/api/site/home/content');
    getPortfolioDetails = (slug) => this.getRequest('/api/site/portfolio/' + slug);
    getHomePortfolioItem = (query) => this.getRequest('/api/site/portfolio/home', query);
    getServicesList = () => this.getRequest(`/api/site/services`);
    getServicesContent = () => this.getRequest('/api/site/services/content');
    getServiceDetails = (slug) => this.getRequest('/api/site/services/' + slug);
    getServicePortfolioItem = (slug, query) => this.getRequest('/api/site/services/portfolio/' + slug, query);
    getHomeAboutContent = () => this.getRequest('/api/site/home/content');
    getAboutUsContent = () => this.getRequest('/api/site/about/content');
    getBriefTitle = () => this.getRequest('/api/site/brief/content');
    getBriefdata = (data) => this.postRequest('/api/mail/brief', data);
    getJobDetailsCv = (data) => this.postRequest('/api/mail/cv', data);
    getSpecialProjectList = () => this.getRequest('/api/site/special-projects');
    getSpecialDetails = (slug) => this.getRequest('/api/site/special-projects/' + slug);
    getSpecialItems = (slug, category) => this.getRequest('/api/site/special-projects/' + slug + `/portfolio`, category);
    getAboutUsImages = () => this.getRequest('/api/site/about/gallery');
    getJobs = () => this.getRequest('/api/site/jobs');
    getJobDetails = (slug) => this.getRequest('/api/site/jobs/' + slug);
    getTeam = () => this.getRequest('/api/site/about/team');
    getPartners = () => this.getRequest('/api/site/about/partners');
}

export default new getData();