import React, { useEffect, useState } from 'react';
import WithHeader from '../../components/with-header-and-footer-hoc'
import './style.scss'
import Api from '../../services';
import { Link, useParams } from 'react-router-dom';
import { useStore } from '../../context';


const ServiceDetails = () => {
    const { slug } = useParams()
    const [data, setData] = useState()
    const [items, setItems] = useState([])
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const { setSelectedServicesType, getLang, tr } = useStore();

    useEffect(() => {
        getItems()
    }, [slug])
    const getItems = () => {
        Api.getData.getServicePortfolioItem(slug, {
            page: page + 1,
            paginate: window.innerWidth < 1024 ? 18 : '',
        })
            .then(res => {
                setItems(st => [...st, ...res?.data.data]);
                setPage(res?.data?.current_page);
                setLastPage(res?.data?.last_page);
            })
        Api.getData.getServiceDetails(slug)
            .then(res => setData(res?.data))
    }
    return (
        <main className='services-page details'>
            <div className='wrapp-container'>
                <div className='page-info'>
                    <h1 className='page_title'>
                        {data?.item?.title}
                    </h1>
                    <div className='services-text main' dangerouslySetInnerHTML={{ __html: data?.item?.text }} />
                    <div className="links-group">
                        <Link onClick={() => setSelectedServicesType(data.item.id)} to="/brief" className='main-btn'>{tr.start}</Link>
                        {data?.item?.file && <a href={data?.item?.file} className="services-link" download>{tr.downloadExample}</a>}
                    </div>
                </div>
                <div className="grid_items">
                    {items?.map((el, i) =>
                        <Link to={`${getLang()}/portfolio/${el.slug}`} className='gallery-row-item'>
                            <div className="image-box"><img alt="Braind Shop" src={`${Api.imgUrl + el.thumbnail}`} alt='' /></div>
                            <span className="image-text">{el.title_en}</span>
                        </Link>
                    )}
                </div>
                {page < lastPage && <button onClick={getItems} className="main-btn primary">
                    {tr.loadMore}
            </button>}
            </div>
        </main>
    );
};

export default WithHeader(ServiceDetails);