import React, { createContext, useContext, useState } from "react";
import { en, hy, ru } from './translations';

export const ContextStore = createContext();

const Context = ({ children }) => {
    const [bag, setBag] = useState(JSON.parse(localStorage.getItem("bag")) || []);
    const [wishlist, setWishlist] = useState(JSON.parse(localStorage.getItem("wishlist")) || []);

    const addToWishlist = id => {
        const wishlistItems = [...wishlist];
        if (!wishlistItems.includes(Number(id))) {
            wishlistItems.push(Number(id))
        }
        setWishlist(wishlistItems);
        localStorage.setItem("wishlist", JSON.stringify(wishlistItems));
    };

    const removeFromWishlist = id => {
        const wishlistItems = [...wishlist];
        const filtered = wishlistItems.filter(item => Number(item) !== Number(id));
        setWishlist(filtered);
        localStorage.setItem("wishlist", JSON.stringify(filtered));
    };

    const syncWishlist = (ids = []) => {
        const wishlistItems = [...wishlist];
        const itemsToRemove = wishlistItems.filter(id => !ids.includes(Number(id)));
        if (itemsToRemove.length) {
            const filtered = wishlistItems.filter(id => !itemsToRemove.includes(Number(id)));
            setWishlist(filtered);
            localStorage.setItem("wishlist", JSON.stringify(filtered));
        }
    }

    const syncBag = (ids = []) => {
        const bagItems = [...bag];
        const itemsToRemove = bagItems.filter(item => !ids.includes(Number(item.id)));
        if (itemsToRemove.length) {
            const removeId = itemsToRemove.map(item => item.id);
            const filtered = bagItems.filter(item => !removeId.includes(Number(item.id)));
            setBag(filtered);
            localStorage.setItem("bag", JSON.stringify(filtered));
        }
    }

    const addToBag = (id, quantity, allow_quantity) => {
        const bagItems = [...bag];
        const itemIndex = bagItems.findIndex(item => Number(item.id) === Number(id));
        if (itemIndex === -1) {
            bagItems.push({
                id: Number(id),
                quantity: Number(quantity)
            })
        } else {
            bagItems[itemIndex].quantity = allow_quantity
                ? Number(bagItems[itemIndex].quantity) + Number(quantity)
                : 1;
        }
        setBag(bagItems);
        localStorage.setItem("bag", JSON.stringify(bagItems));
    };

    const removeFromBag = id => {
        const bagItems = [...bag];
        const filtered = bagItems.filter(item => Number(item.id) !== Number(id));
        setBag(filtered);
        localStorage.setItem("bag", JSON.stringify(filtered));
    };

    const updateProductInBag = (oldId, newId, quantity = null) => {
        const bagItems = [...bag];
        const newBag = bagItems.map(item => {
            if (Number(item.id) === Number(oldId)) {
                item.id = Number(newId);
                if (quantity) {
                    item.quantity = quantity;
                }
                return item;
            }

            return item;
        });
        setBag(newBag);
        localStorage.setItem("bag", JSON.stringify(newBag));
    }

    const updateProductInWishlist = (oldId, newId) => {
        const wishlistItems = [...wishlist];
        const newWishlist = wishlistItems.map(id => {
            if (Number(id) === Number(oldId)) {
                return newId;
            }
            return id;
        });
        setWishlist(newWishlist);
        localStorage.setItem("wishlist", JSON.stringify(newWishlist));
    }

    const clearBag = () => {
        setBag([]);
        localStorage.setItem("bag", JSON.stringify([]));
    }

    const updateBag = (id, quantity) => {
        const bagItems = [...bag];
        const filtered = bagItems.filter(item => Number(item.id) !== Number(id));
        filtered.push({ id, quantity });
        setBag(filtered);
        localStorage.setItem("bag", JSON.stringify(filtered));
    }

    const getLang = () => {
        const lang = localStorage.getItem('lang');
        if (lang === 'en') {
            return '';
        } else {
            return `/${lang}`;
        }
    }

    const transaction = () => {
        switch (localStorage.getItem('lang')) {
            case 'ru':
                return ru;
            case 'hy':
                return hy;
            default:
                return en;
        }
    }

    const tr = transaction();

    const [startProjectBottom, setStartProjectBottom] = useState(true)
    const [selectedServicesType, setSelectedServicesType] = useState(false)
    const [text, setText] = useState()

    return (
        <ContextStore.Provider
            value={{
                bag, wishlist, addToBag, removeFromBag, addToWishlist, removeFromWishlist, clearBag, updateBag,
                getLang, transaction, updateProductInBag, updateProductInWishlist, syncBag, syncWishlist, tr,
                startProjectBottom, setStartProjectBottom, selectedServicesType, setSelectedServicesType, text, setText
            }}
        >
            {children}
        </ContextStore.Provider>
    );
};

export default Context;

export const useStore = () => useContext(ContextStore);
