import Api from "../../../../services";
import xbutton from "../../../../assets/xbutton.png";
import React, {memo, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import minus from '../../../../assets/minus.svg';
import plus from '../../../../assets/plus.svg';
import {useStore} from "../../../../context";
import blackdram from "../../../../assets/black-price.svg"
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const DesktopItem = props => {
    const { item } = props;
    const [productCount, setProductCount] = useState(Number(item.quantity));
    const { removeFromBag, updateBag, updateProductInBag, tr } = useStore();

    useEffect(()=>{
        updateBag(item.id, productCount);
    }, [productCount]);

    const changeSizeHandler = e => {
        const newItemId = e.target.value;
        updateProductInBag(item.id, newItemId)
    }


    const productCounterHandler = (param) => {
        switch (param) {
            case '-':
                setProductCount(count => count > 1 ? --count : count)
                break;
            case '+':
                setProductCount(count => ++count)
                break;
        }
    };
    return (
        <tr>
            <td>
            <Link class='link-brd' to={`product/${item.slug}`}>
                <div className="cart_item_image_title_container">
                    <div className="cart_item_image">
                        {item.images && <img src={`${Api.imgUrl}${item.images?.[0]?.image}`} />}
                    </div>
                    <div className="cart_item_title">
                        {item.title}
                    </div>
                </div>
                </Link>
            </td>
            <td>
                {item.sizes.length > 0 &&
                <div className="cart_mobile_des_quality cart_item_quality" id="td-mrg">
                    <FormControl>
                        <Select
                            native
                            value={Number(item.id)}
                            onChange={changeSizeHandler}
                        >
                            {item.sizes.map(sizeItem => <option value={Number(sizeItem.stock_id)}>{sizeItem.size.size}</option>)}
                        </Select>
                    </FormControl>
                </div>}
            </td>
            <td>
                {item?.allow_quantity &&
                    <div className="product_count_container">
                  <div className="product_count_button" onClick={()=>productCounterHandler('-')}>
                        <img alt="Braind Shop" src={minus} />
                    </div>
                    <input type="text" readOnly className="product_count_input" value={productCount}/>
                    <div className="product_count_button" onClick={()=>productCounterHandler('+')}>
                        <img alt="Braind Shop" src={plus} />
                    </div>
                </div>}
            </td>
            <td>
                <div className="cart_item_price">
                {item?.cost > 0 ? item.cost.toLocaleString('de-DE')  : tr.free} {item?.cost > 0 && <img src={blackdram} />}
                </div>
            </td>
            <td>
                <div className="x_button" onClick={()=>removeFromBag(Number(item.id))}>
                    <img  alt="button" src={xbutton}/>
                </div>
            </td>
        </tr>
    );
}

export default memo(DesktopItem);