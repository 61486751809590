import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import WithHeader from '../../components/with-header-and-footer-hoc'
import Api from '../../services'
import './style.scss'
import { useStore } from '../../context';
import InfiniteScroll from 'react-infinite-scroller';

const Portfolio = () => {
    const [categories, setCategories] = useState();
    const [tab, setTab] = useState('');
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [infinityKey, setinfinityKey] = useState(1);
    const { getLang, tr } = useStore();

    const getCategories = () => {
        setHasMore(true);
        Api.portfolio.getCategories()
            .then(res => setCategories(res.data.items))
    }

    const changeCategoryHandler = () => {
        setPage(1);
        setItems([]);
        setHasMore(true);
        setinfinityKey(st => ++st);
    }

    useEffect(getCategories, []);
    useEffect(changeCategoryHandler, [tab]);

    const itemsHandler = (data = []) => {
        setItems(st => [
            ...st,
            ...data
        ]);
    };

    const loadMore = () => {
        setHasMore(false);
        Api.portfolio.getPortfolioItems({
            ...tab && { category: tab },
            paginate: window.innerWidth < 1024 ? 18 : '',
            page: page,
        })
            .then(res => {

                const item = res?.data.items;
                itemsHandler(item.data);
                const nextPage = parseInt(item.current_page) + 1;
                setPage(nextPage);
                if (Number(item.current_page) >= Number(item.last_page)) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            })
    };

    return (
        <main className='portfolio_page'>
            <span className='page_title' data-aos='fade-up' data-aos-delay={300}>{tr.portfolio}</span>
            <div data-aos='fade-up' data-aos-delay={350} className='buttons_tab'>
                <button onClick={() => setTab('')} className={tab === '' ? 'active' : ''}>{tr.all}</button>
                {categories?.map(el => <button onClick={() => setTab(el.id)} className={tab === el.id ? 'active' : ''}>{el.title}</button>)}
            </div>

            <InfiniteScroll className="shop-grid-container"
                key={infinityKey}
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                threshold={250}>
                <div className='grid_items'>
                    {items?.map((el, i) =>
                        <Link data-aos='fade-up' data-aos-delay={`${i + 4}00`} to={`${getLang()}/portfolio/${el.slug}`} key={el.id}  >
                            <div className="image-box"><img alt="Braind Shop" src={Api.imgUrl + el.thumbnail} /></div>
                            <span  >{el.title}</span>
                        </Link>
                    )}
                </div>
            </InfiniteScroll>
        </main>
    );
};

export default WithHeader(Portfolio);