import React, { useState, memo, useEffect } from "react";
import Api from "../../../../services";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import xbutton from "../../../../assets/xbutton.png";
import minus from '../../../../assets/minus.svg';
import plus from '../../../../assets/plus.svg';
import { useStore } from "../../../../context";
import blackdram from '../../../../assets/black-price.svg';

const MobileItem = props => {
    const { item, addToBag, removeFromWishlist } = props;
    const [productCount, setProductCount] = useState(1);
    const { removeFromBag, updateBag, updateProductInWishlist, setText, text } = useStore();
    const { tr } = useStore();
    const addToBagHandler = id => {
        addToBag(id, productCount, item.allow_quantity);
        removeFromWishlist(id);
    }


    const changeSizeHandler = e => {
        const newItemId = e.target.value;
        updateProductInWishlist(item.id, newItemId);
        setProductCount(1)
    }

    const productCounterHandler = (param) => {
        switch (param) {
            case '-':
                setProductCount(count => count > 1 ? --count : count)
                break;
            case '+':
                setProductCount(count => ++count)
                break;
        }
    };

    return (
        <div className="card_mobile_item">
            <div className="card_mobile_item_content">
                <div className="cart_mobile_item_image">
                    {item.images && <img alt="price" src={`${Api.imgUrl}${item.images?.[0]?.image}`} />}
                </div>
                <div className="cart_mobile_des_container">
                    <div className="cart_mobile_des_title">
                        {item.title}
                    </div>
                    <div className="cart_mobile_des_size">
                        {item?.cost > 0 ? item.cost.toLocaleString('de-DE') : tr.free} {item?.cost > 0 && <img alt="price" src={blackdram} />}
                    </div>
                    <div className="forms-carts">
                        {item.sizes.length > 0 && <div className="cart_mobile_des_quality cart_item_quality">
                            <FormControl>

                                <Select
                                    native
                                    value={Number(item.id)}
                                    onChange={changeSizeHandler}
                                >
                                    {item.sizes.map(sizeItem => <option value={Number(sizeItem.id)}>{sizeItem.size.size}</option>)}
                                </Select>
                            </FormControl>
                        </div>}
                        {item?.allow_quantity && !item?.out_of_stock &&
                            <div className="product_count_container wishlist-inp">
                                <div className="product_count_button" onClick={() => productCounterHandler('-')}>
                                <img alt="Braind Shop" src={minus} />
                                </div>
                                <input type="text" readOnly className="product_count_input" value={productCount} />
                                <div className="product_count_button" onClick={() => productCounterHandler('+')}>
                                <img alt="Braind Shop" src={plus} />
                                </div>
                            </div>}
                    </div>
                    <div className="wishlist_item_container">
                        {!item?.out_of_stock &&
                            <div className="wishlis_item_button"
                            // onClick={() => addToBagHandler(item.id)}
                            onMouseOver={() => setText(true)} onMouseLeave={() => setText(false)}
                            >
                                {text ?
                                    tr.addToBagHover
                                    :
                                    tr.addToBag
                                }
                            </div>}
                        <div className="cart_mobile_item_x_container" onClick={() => removeFromWishlist(item.id)}>
                            <img alt="Braind Shop" src={xbutton} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default memo(MobileItem);