export default {
    all: 'all',
    filter: {
        sale: 'Sale',
        priceHighLow: 'Price(high - low)',
        priceLowHigh: 'Price(low - high)',
    },
    sortBy: 'sort by',
    cart: 'Cart',
    sendMessage: 'Send Message',
    send: 'Send',
    name: 'Name',
    email: 'email',
    phone: 'phone',
    text: 'Text',
    portfolio: 'PORTFOLIO',
    company: 'COMPANY',
    services: 'SERVICES ',
    start: 'start a project',
    shop: 'shop',
    goBackToShop: 'Go back to shopping',
    contact: 'CONTACTS',
    black_fridyan: 'BLACK FRIDAY',
    special_project: 'Special Projects',
    favoriteSong: 'favorite song',
    paymentAndDelivery: 'Payment and Delivery',
    paymentMethods: 'Payment Methods',
    privacyPolicy: 'Privacy Policy',
    popupSheduleTitle: 'Yey!',
    popupSheduleText: 'Looking forward to discussing your project and starting to work on it.',
    popupOfferTitle: 'Thank You! ',
    popupOfferText: " We will send you the price offer in 1 - 2 working days.Please, check your Spam and if it's not there, let us know at masha@braind.am. ",
    allRightsReserved: 'All rights reserved',
    addToBag: 'Add to bag',
    emptyBag: 'Your cart is empty',
    addToBagHover: 'Coming soon',
    packageInclude: 'Package Include',
    free: 'Free',
    delivery: 'Delivery',
    similarProducts: 'Similar Products',
    item: 'Item',
    size: 'Size',
    quantity: 'Quantity',
    price: 'Price',
    subtotal: 'Subtotal',
    discount: 'Discount',
    total: 'Total',
    goToPayment: 'go to payment',
    wishlist: 'Wishlist',
    personalDetails: 'Personal Details',
    surname: 'Surname',
    donate: 'donate',
    addNotes: 'Add Notes',
    byClicking: 'By clicking you accept our',
    termsAndConditions: 'terms and conditions ',
    receiveNews: 'Receive our news and promotions',
    donateAndDownload: 'donate and download',
    getDownloadLink: 'get download link',
    buyingFromBraind: 'Buying from braind.am is safe.',
    yourOrderWill: 'Your order will be processed on our secure servers',
    loadMore: 'load more',
    downloadPresentation: 'download presentation',
    haveAProject: 'Have a project?',
    getStarted: 'get started',
    whatWeOffer: 'What we offer?',
    ourServices: 'Our services',
    giveACall: 'Give us a call',
    getInTouch: 'Get in touch',
    weAreLocated: 'We are located',
    client: 'Client',
    country: 'Country',
    year: 'Year',
    shareWithFriends: 'Share with Friends',
    relatedProjects: 'Related projects',
    allProjects: 'All projects',
    callUs: 'Call Us',
    writeUs: 'Write us',
    address: 'Address',
    downloadExample: 'Download AN EXAMPLE',
    whatDoYouWant: 'What do you want?',
    priceOffer: 'PRICE OFFER',
    scheduleCall: 'Schedule a Call',
    callType: 'Preferred call type',
    selectLanguage: 'Select language',
    armenian: 'Armenian',
    english: 'English',
    russian: "Russian",
    phoneCall: 'Phone call',
    productType: 'Product type',
    wrong: 'Something went wrong',
    company_details: 'Company details',
    company_name: 'Company name',
    tell_about_project: 'Tell us about your project',
    attachFile: 'Attach file',
    phone_number: 'Phone number',
    date_and_time: 'Date and time',
    know_about_us: 'How do you know about us?',
    social_networks: 'Social networks',
    recomendations: 'Recommendations',
    rating: 'Rating',
    required_skills: 'Required Skills',
    about_portfolio: 'Tell us about yourself and send your portfolio',
    benefit_pack: 'Braind benefit pack',
    date: 'Date',
    time: 'Time',
}