import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import withHeader from '../../components/with-header-and-footer-hoc'
import Api from '../../services';

import './style.scss'
import { useStore } from '../../context';

const BlackFriday = () => {
    const [id, setId] = useState('')
    const [data, setData] = useState()
    const [item, setItem] = useState()
    const { getLang, tr } = useStore()
    const { slug } = useParams()
    useEffect(() => {
        Api.getData.getSpecialDetails(slug)
            .then(res => setData(res?.data?.item))
        getData('')
    }, [slug])

    const getData = (id) => {
        setId(id)
        Api.getData.getSpecialItems(slug, { category: id })
            .then(res => setItem(res?.data?.items))
    }

    return (
        <main className='black_friday_page'>
            <img alt="black friday" src={Api.imgUrl + data?.cover_image} data-aos='fade-up' data-aos-delay={300} />
            <div className='wrapper'>

                <h1 className='page_title' data-aos-delay={350}>{data?.title}</h1>
                <ul>
                    <li data-aos='fade-up' data-aos-delay={450} dangerouslySetInnerHTML={{ __html: data?.conditions }} />
                </ul>
                <div data-aos='fade-up' data-aos-delay={550} className='buttons_tab'>
                    {data && <button onClick={() => getData('')} className={id === '' ? 'active' : ''}>{tr.all}</button>}
                    {data?.categories.map((el, i) => <button onClick={() => getData(el?.id)} className={el?.id === id ? 'active' : ''}>{el.title}</button>)}
                </div>
                <div className='grid_items'>
                    {item?.map((el, i) =>
                        <Link to={`${getLang()}/portfolio/${el.slug}`} key={el.id}>
                            <div className="image-box"><img alt="portfolio" data-aos='fade-up' data-aos-delay={750} src={Api.imgUrl + el.thumbnail} /></div>
                            <span data-aos='fade-up' data-aos-delay={850} >{el.title}</span>
                        </Link>
                    )}
                </div>

            </div>
        </main>
    );
};

export default withHeader(BlackFriday, true);