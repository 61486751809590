import React, { useEffect } from 'react';
import './styles.scss';
import Icon from '../../icon';
import Outside from '../../outside';

const ConfirmPopup = ({ openPopup, togglePopup, titlePopup, textPopup }) => {

    useEffect(() => {
        openPopup
            ? document.body.classList.add('body-fix')
            : document.body.classList.remove('body-fix')
    }, [openPopup]);
    return (
        <div className={`modal_popup ${openPopup ? 'active' : ''}`}>
            <Outside action={openPopup ? () => { togglePopup() } : null} >
                <div className={`popup_container ${openPopup ? 'active' : ''}`}>
                    <div className='content'>
                        <Icon id='close' onClick={() => { togglePopup() }} />
                        <div className='icon'>
                            <Icon id='done' />
                        </div>
                        <h2 className='title'>
                            {titlePopup}
                        </h2>
                        <p className="text">
                            {textPopup}
                        </p>
                    </div>
                </div>
            </Outside>
        </div>
    )
}

export default ConfirmPopup;