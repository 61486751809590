import React, { useCallback, useEffect, useState } from 'react';
import WithHeader from '../../components/with-header-and-footer-hoc'
import './style.scss'
import RadioSection from './radio-section/radio-section';
import ProductSection from './product-section/product-section';
import AboutSection from './about-section/about-section';
import CompanySection from './company-section/company-section'
import DateSection from './date-section';
import ConfirmPopup from '../../components/confirm-popup';
import Api from '../../services';
import CustomInput from '../../components/input/input';
import moment from 'moment';
import { useStore } from '../../context';
import LanguageSection from './language-section/language-section';




const SendBrief = () => {
    const [sheduleCall, setSheduleCall] = useState();
    const [openPopup, setOpenPopup] = useState(false)
    const [nameInput, setNameInput] = useState('');
    const [sheduleNameInput, setSheduelNameInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyNameError, setCompanyNameError] = useState(false);
    const [aboutCompany, setAboutCompany] = useState('');
    const [surNameInput, setSurName] = useState('');
    const [social, setSocial] = useState('social_networks');
    const [phoneInput, setPhoneInput] = useState('');
    const [phoneInputOffer, setPhoneInputOffer] = useState('');
    const [phoneInputError, setPhoneInputError] = useState('');
    const [activeProductShedule, setActiveProductShedule] = useState([]);
    const [languageError, setLanguageError] = useState(false);
    const { selectedServicesType, tr } = useStore();
    const [activeProductOffer, setActiveProductOffer] = useState(selectedServicesType ? [selectedServicesType] : []);
    const [activeProductOfferError, setActiveProductOfferError] = useState('');
    const [activeCallType, setActiveCallType] = useState([]);
    const [callError, setCallError] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorShedule, setNameErrorShedulde] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [file, setFile] = useState();
    const [titlePopup, setTitlePopup] = useState();
    const [textPopup, setTextPopup] = useState()
    const togglePopup = () => {
        setOpenPopup(val => !val)
        document.body.classList.toggle('body-fix');
    }


    const languageState = [
        { text: tr.armenian, val: ' armenian' },
        { text: tr.english, val: 'english' },
        { text: tr.russian, val: 'russian' },
    ]

    const callTypeState = [
        { text: tr.phoneCall, val: ' phone_call' },
        { text: 'Skype', val: ' skype' },
        { text: 'Viber', val: ' viber' },
        { text: 'WhatsApp', val: ' whatsapp' },
    ]

    const getForm = () => {
        const form = new FormData();
        if (!!sheduleCall) {
            form.append('name', sheduleNameInput);
            form.append('type', 'schedule_call');
            form.append('phone', phoneInput);
            form.append('language', activeProductShedule);
            form.append('call_type', activeCallType);
            { startTime && form.append('time', moment(startTime).format('HH:mm')); }
            { startDate && form.append('date', moment(startDate).format('DD.MM.YYYY')); }

        } else {
            form.append('name', nameInput);
            form.append('email', emailInput);
            form.append('type', 'price_offer');
            form.append('surname', surNameInput);
            { phoneInputOffer && form.append('phone', phoneInputOffer); }
            form.append('company_name', companyName);
            form.append('about_project', aboutCompany);
            form.append('product_type', activeProductOffer);
            { file && form.append('attachment', file); }
        }
        form.append('how_know', social);
        return form;
    };
    const [data, setData] = useState()
    const [list, setList] = useState()
    useEffect(() => {
        Api.getData.getServicesList()
            .then(res => setList(res?.data.items))
        Api.getData.getBriefTitle()
            .then(res => setData(res?.data?.item))
    }, []);


    const submitHandler = () => {
        const nameStatus = !nameInput;
        const companyNameStatus = !companyName;
        const activeProductStatus = !activeProductOffer.length;
        const emailStatus = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput));
        setNameError(nameStatus);
        setEmailError(emailStatus);
        setActiveProductOfferError(activeProductStatus);
        setCompanyNameError(companyNameStatus);
        const form = getForm();
        Api.getData.getBriefdata(form).then(() => {
            setTitlePopup(tr.popupOfferTitle)
            setTextPopup(tr.popupOfferText)
            togglePopup()
            setNameInput('');
            setEmailInput('');
            setActiveProductOffer('')
            setSurName('');
            setCompanyName('')
            setPhoneInputOffer('');
            setAboutCompany('')
            setFile(null)
        })
    }
    const submitHandlerShedule = () => {
        const phoneStatus = !phoneInput;
        const languageStatus = !activeProductShedule.length;
        const nameStatusShedule = !sheduleNameInput;
        const callTypeStatus = !activeCallType.length;
        setNameErrorShedulde(nameStatusShedule);
        setPhoneInputError(phoneStatus)
        setCallError(callTypeStatus)
        setLanguageError(languageStatus)
        const form = getForm();
        Api.getData.getBriefdata(form).then(() => {
            setTitlePopup(tr.popupSheduleTitle)
            setTextPopup(tr.popupSheduleText)
            togglePopup()
            setSocial('')
            setActiveProductShedule('')
            setActiveCallType('')
            setPhoneInput('')
            setStartDate('')
            setSheduelNameInput('')
            setStartTime('')
        });
    }

    return (
        <>
            <section className="page__container brief">
                <div className="content">
                    <div className="title">
                        <h1 data-aos="fade-up" data-aos-delay={300}>{data?.title}</h1>
                        <span data-aos="fade-up" data-aos-delay={400} dangerouslySetInnerHTML={{ __html: data?.text }} />
                    </div>
                    <form className='form-container form_wrap' data-aos="fade-up" data-aos-delay={500}>
                        <RadioSection title={tr.whatDoYouWant} action={setSheduleCall} />
                        {!!sheduleCall ? <LanguageSection error={languageError} activeProduct={activeProductShedule}
                            setActiveProduct={setActiveProductShedule}
                            title={tr.selectLanguage}
                            state={languageState} />
                            :
                            <ProductSection error={activeProductOfferError} activeProduct={activeProductOffer}
                                setActiveProduct={setActiveProductOffer}
                                title={tr.productType}
                                state={list}
                            />
                        }

                        {
                            sheduleCall ?
                                <div className="input-wrapp product">
                                    <span className='subtitle'>{tr.callType}</span>
                                    <div className="input-box">
                                        <span className={`${callError ? 'error' : ''}`}>{tr.wrong}</span>
                                        {callTypeState.map((el, i) =>
                                            <div
                                                onClick={() => setActiveCallType(st => st.includes(el.val) ? st.filter(e => e !== el.val) : [...st, el.val])}
                                                className={`check-button ${activeCallType.includes(el.val) ? 'active' : ''}`}>{el.text}</div>
                                        )}
                                    </div>
                                </div>
                                :
                                <CompanySection error={companyNameError} file={file} setFile={setFile} aboutCompany={aboutCompany} setAboutCompany={setAboutCompany} companyName={companyName} setCompanyName={setCompanyName} title={tr.company_details} />
                        }
                        <div className="input-wrapp personal">
                            <span className="subtitle">{tr.personalDetails}</span>
                            {sheduleCall ?
                                <div className='personal-wrapp'>
                                    <CustomInput error={nameErrorShedule} onChange={e => setSheduelNameInput(e.target.value)}
                                        val={sheduleNameInput} text={tr.name} required />
                                    <CustomInput error={phoneInputError} onChange={e => setPhoneInput(e.target.value)}
                                        val={phoneInput} type="number" text={tr.phone_number} required />
                                </div>
                                :
                                <div className='personal-wrapp'>
                                    <CustomInput error={nameError} onChange={e => setNameInput(e.target.value)}
                                        val={nameInput} text={tr.name} required />
                                    <CustomInput onChange={e => setSurName(e.target.value)}
                                        val={surNameInput} text={tr.surname} />
                                    <CustomInput error={emailError} onChange={e => setEmailInput(e.target.value)}
                                        val={emailInput} type={'email'} text={tr.email} required />
                                    <CustomInput onChange={(e) => {
                                        const { value } = e.target;
                                        if (value === '' || /^[0-9\b]+$/.test(value)) {
                                            setPhoneInputOffer(value)
                                        }
                                    }}
                                        val={phoneInputOffer} type='text' text={tr.phone_number} />
                                </div>
                            }
                        </div>
                        {sheduleCall && <DateSection startTime={startTime} setStartTime={setStartTime} startDate={startDate} setStartDate={setStartDate} title={tr.date_and_time} />}
                        <AboutSection setSocial={setSocial} social={social} title={tr.know_about_us} />
                        <button data-aos="zoom-in" data-aos-delay={400} className="main-btn" onClick={() => sheduleCall ? submitHandlerShedule() : submitHandler()} type='button'>{tr.send}</button>
                    </form>
                </div>
            </section>
            <ConfirmPopup textPopup={textPopup} titlePopup={titlePopup} openPopup={openPopup} togglePopup={() => togglePopup()} />
        </>
    );
};

export default WithHeader(SendBrief);