import React, { useRef } from 'react'
import Icon from '../../../icon';
import CustomInput from '../../../components/input/input';
import './styles.scss'
import { useStore } from '../../../context';


const CompanySection = ({ error, title, companyName, setCompanyName, aboutCompany, setAboutCompany, setFile, file }) => {
    const { tr } = useStore()

    return (
        <div className="input-wrapp company">
            <span className="subtitle">{title}</span>
            <div className="text_inp">
                <CustomInput error={error} onChange={e => setCompanyName(e.target.value)}
                    val={companyName} text={tr.company_name} required type />
            </div>
            <div className="text_area">
                <textarea onChange={e => setAboutCompany(e.target.value)}
                    value={aboutCompany} className="inp" placeholder={tr.tell_about_project} />
            </div>
            <div className="doc_wrap">
                <label className="documents">
                    <div className="attach">
                        <Icon id="attach" />
                    </div>
                    {!file && <span >{tr.attachFile}</span>}
                    {!!file && <span >{file.name}</span>}
                    <input key={file} type="file" name="file" onChange={(e) => setFile(e.target.files[0])} />
                </label>
                {!!file && <div className="resetFile" onClick={() => setFile(null)}><Icon id='deleteFile' /></div>}
            </div>

        </div >
    )
}

export default CompanySection; 