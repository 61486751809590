import BaseRequestService from "./base-request-service";

class text_page extends BaseRequestService {
    getDelivery = () => this.getRequest('/api/text-pages/payment-and-delivery');
    getPaymentMethodTextContent = () => this.getRequest('/api/text-pages/payment-methods');
    getPrivacyPolicyTextContent = () => this.getRequest('/api/text-pages/privacy-policies');
    getSuccessPopupContent = () => this.getRequest('/api/text-pages/success-popup');

    

}

export default new text_page();