import React, { useState } from 'react'
import Radio from '../../../components/radio/radio'
import { useStore } from '../../../context'
import './styles.scss'




const AboutSection = ({ title, social, setSocial, }) => {
    const { tr } = useStore();
    
    const testState = [
        { text: tr.social_networks, _id: 'social_networks', checked: false },
        { text: tr.recomendations, _id: 'recommendations', checked: false },
        { text: tr.rating, _id: 'rating', checked: false },
        { text: 'Google', _id: 'google', checked: false }
    ]
    return (
        <div className="input-wrapp about">
            <span className="subtitle">{title}</span>
            <div className='input-box'>
                {testState.map((el, id) =>
                    <Radio
                        text={el.text}
                        onChange={() => { setSocial(el._id); }
                        }
                        value={social === el._id}
                    />
                )}
            </div>
        </div>
    )
}

export default AboutSection; 