import React, { memo, useEffect, useState } from "react";
import "./style.scss";
import Slider from "react-slick";
import { ProductsPartial } from "../shop-page-grid/_partials";
import Api from '../../../services';
import { Link } from "react-router-dom";
import { useStore } from "../../../context";

const setting = {
    slidesToShow: 5.68,
    slidesToScroll: 1,
    autoplay: false,
    infinite: false,
    swipeToSlide: false,
    arrows: true,
    pauseOnHover: false,
    cssEase: 'ease',
    touchThreshold: 100,
    varibaleWidth: false,
    responsive: [
        {
            breakpoint: 1550,
            settings: {
                slidesToShow: 4,
                varibaleWidth: false,
                slidesToScroll: 1,

            }
        },
        {
            breakpoint: 1024,
            settings: {
                infinite: false,
                arrows: false,
                slidesToShow: 3.19,
                slidesToScroll: 1,
                varibaleWidth: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                arrows: false,
                slidesToShow: 2.03,
                varibaleWidth: true,
                slidesToScroll: 1,
            }
        }
    ]
};


const ItemsSlider = () => {
    const [data, setData] = useState(null);
    const { getLang, tr } = useStore();

    useEffect(() => {
        Api.products.getProductsHome()
            .then(res => setData(res?.data?.data))
    }, [])


    return (
        <section className='product_slider_bottom' >
            <Link to={`${getLang()}/shop`}>
                <h3 data-aos='fade-up' data-aos-delay={600}>
                    {tr.shop}
                 </h3>
            </Link>
            <Slider {...setting}>
                {data?.map((el, i) =>
                    <ProductsPartial item={el} />
                )}
            </Slider>
        </section>
    );
}

export default memo(ItemsSlider);