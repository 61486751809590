export default {
    all: 'все',
    filter: {
        sale: 'Распродажа',
        priceHighLow: 'Цена(в убывающем порядке)',
        priceLowHigh: 'Цена(в возрастающем порядке)',
    },
    sortBy: 'Сортировать по',
    cart: 'Корзина',
    sendMessage: 'Отправить сообщение',
    send: 'Отправить',
    name: 'Имя',
    shop: 'магазин',
    portfolio: 'Портфолио',
    company: 'О НАС',
    services: 'УСЛУГИ',
    start: 'начать проект',
    contact: 'КОНТАКТЫ',
    black_fridyan: 'ЧЕРНАЯ ПЯТНИЦА',
    special_project: 'специальные проекты',
    email: 'Эл. почта',
    phone: 'номер телефона',
    text: 'Текст',
    favoriteSong: 'любимая песня',
    paymentAndDelivery: 'Оплата и Доставка',
    paymentMethods: 'Способы оплаты',
    privacyPolicy: 'Privacy Policy',
    allRightsReserved: 'Все права защищены',
    addToBag: 'Добавить в корзину',
    addToBagHover: 'скоро будет',
    packageInclude: 'Пакет включает',
    free: 'Бесплатно',
    delivery: 'Доставка',
    popupSheduleTitle: 'Ура!',
    popupSheduleText: 'С нетерпением ждем обсуждения вашего проекта и начала работы над ним. ',
    similarProducts: 'Похожие продукты',
    item: 'продукт',
    size: 'Размер',
    quantity: 'Количество',
    price: 'Цена',
    subtotal: 'Промежуточный итог',
    discount: 'Скидка',
    total: 'Итог',
    goBackToShop: 'Вернуться к покупкам',
    goToPayment: 'перейти к оплате',
    wishlist: 'Отложить',
    personalDetails: 'Личные Данные',
    surname: 'Фамилия',
    donate: 'пожертвовать',
    popupOfferTitle: 'Спасибо!',
    popupOfferText: " Мы отправим Вам ценовое предложение в течение 1-2 рабочих дней. Пожалуйста, проверьте свой спам и если его там нет, сообщите нам на masha@braind.am. ",
    emptyBag: 'Ваша корзина пуста',
    addNotes: 'Добавить заметки',
    byClicking: 'Нажав, вы принимаете наши',
    termsAndConditions: 'условия ',
    receiveNews: 'Получать наши новости и акции',
    donateAndDownload: 'пожертвовать и скачать',
    getDownloadLink: 'получить ссылку дла скачивания',
    buyingFromBraind: 'Делать покупки на braind.am безопсно.',
    yourOrderWill: 'Ваш заказ будет обработан на наших защищенных серверах',
    loadMore: 'загрузить',
    downloadPresentation: 'СКАЧАТЬ ПРЕЗЕНТАЦИЮ',
    haveAProject: 'Есть проект?',
    getStarted: 'начать',
    whatWeOffer: 'Что мы предлагаем?',
    ourServices: 'Наши услуги',
    giveACall: 'Позвоните нам',
    getInTouch: 'Связаться',
    weAreLocated: 'Мы находимся',
    client: 'Клиент',
    country: 'Страна',
    year: 'Год',
    shareWithFriends: 'Поделиться с друзьями',
    relatedProjects: 'Связанные проекты',
    allProjects: 'Все проекты',
    callUs: 'Позвоните нам',
    writeUs: 'Напишите нам',
    address: 'Адрес',
    downloadExample: 'Скачать пример',
    whatDoYouWant: 'Что вас интересует?',
    priceOffer: 'Ценовое предложение',
    scheduleCall: 'Назначить звонок / встречу',
    callType: 'Предпочитаемый тип звонка',
    selectLanguage: 'Выбрать язык',
    armenian: 'Армянский',
    english: 'Английский',
    russian: 'Русский',
    phoneCall: 'Телефонный звонок',
    productType: 'Интересующая услуга',
    wrong: 'Что-то пошло не так',
    company_details: 'О вашем проекте',
    company_name: 'Название компании',
    tell_about_project: 'Расскажите о своем проекте',
    attachFile: 'Прикрепить файл',
    personalDetails: 'Личная информация',
    phone_number: 'Телефон',
    date_and_time: 'Дата и время',
    know_about_us: 'Как вы  узнали о нас??',
    social_networks: 'Соц сети',
    recomendations: 'Друзья',
    rating: 'Рейтинг',
    required_skills: 'Требуемые навыки',
    about_portfolio: 'Расскажите коротко о себе и отправьте портфолио',
    benefit_pack: 'Преимущества работы в Braind',
    date: 'Дата',
    time: 'Время',
}