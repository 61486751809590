import React, {memo, useEffect, useState} from "react";
import "./payment-header.css";
import paymentlogo from "../../../assets/BraindLogowhite.svg";
import blackdram from "../../../assets/black-price.svg";
import dram from "../../../assets/amd.svg";
import {useStore} from "../../../context";



const PaymentHeader = props => {
    const { data } = props;
    const { tr } = useStore();
    return(
        <div className="payment-header">
            <div className="payment_header_top_side">
                <img alt="Braind logo" src={paymentlogo} />
                <div className="payment_header_title">
                    Complete your purchase
                </div>
            </div>
            <div className="payment_header_order">
                <div className="payment_header_order_section">
                    <div className="payment_header_order_section_title">
                        Order Summery
                    </div>
                    <div className="payment_order_section_pricing">
                        {data?.items?.map( item =>
                            <div className="payment_order_pricing_row">
                                <div className="payment_order_pricing_title">
                                    {item.title}
                                </div>
                                <div className="payment_order_pricing_value">
                                    
                                    {Number(item.cost) > 0 ? Number(item.cost).toLocaleString('de-DE') : tr.free * Number(item.quantity)
                                    > 0 ? Number(item.quantity) : tr.free}
                                    {Number(item.cost) > 0 && <img alt="Braind Shop" src={blackdram} />}
                                </div>
                            </div>)}

                        {data?.calculate?.total > 0 &&
                            <div className="payment_order_pricing_row">
                                <div className="payment_order_pricing_title">
                                    {tr.delivery}
                                </div>
                                <div className="payment_order_pricing_value blue_color">
                                    {tr.free}
                                </div>
                            </div>}
                    </div>
                    <div className="payment_header_order_total_coast">
                        <div className="payment_total_coast_title">
                            {tr.total}
                        </div>
                        <div className="payment_total_coast_value blue_color">
                            {data?.calculate?.total > 0 ? data?.calculate?.total.toLocaleString('de-DE') : tr.free }
                            {data?.calculate?.total > 0 && <img alt="price" src={dram} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(PaymentHeader);