import React, { useState, useEffect, memo } from "react";
import "./wishlist-content.css";
import { makeStyles } from "@material-ui/core/styles";
import { useStore } from '../../../context';
import Api from '../../../services';
import DesktopItem from './_partials/desktop-item';
import MobileItem from './_partials/mobile-item';


const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const WishlistContent = () => {
    const { addToBag, removeFromWishlist, wishlist, syncWishlist, tr } = useStore();
    const [wishlistItems, setWishlistItems] = useState([]);

    useEffect(() => {
        if (wishlist.length > 0) {
            Api.wishlist.getWishlist({
                list: wishlist
            })
                .then(response => {
                    const ids = response?.data?.items?.map(item => item.id);
                    syncWishlist(ids)
                    setWishlistItems(response?.data?.items)
                })
        }
    }, [wishlist]);

    return (
        <>
            <div className="cart_content_container">
                <div className="cart_content">
                    <div className="cart_content_title">
                        {tr.wishlist} ({wishlist?.length})
                    </div>
                    <div className="cart_grid_main_container">
                        <table cellPadding="0" cellSpacing="0" className="cart_desktop_table">
                            <tr>
                                <th>
                                    {tr.item}
                                </th>
                                <th>
                                    {tr.size}
                                </th>
                                <th>
                                    {tr.quantity}
                                </th>
                                <th>
                                    {tr.price}
                                </th>
                                <th> </th>
                            </tr>
                            {!!wishlist.length && wishlistItems.map((item, index) =>
                                <DesktopItem
                                    key={item.id}
                                    item={item}
                                    addToBag={addToBag}
                                    removeFromWishlist={removeFromWishlist} />
                            )}

                        </table>
                        <div className="card_grid_mobile_container">
                            {!!wishlist.length && wishlistItems.map((item, index) =>
                                <MobileItem
                                    key={item.id}
                                    item={item}
                                    addToBag={addToBag}
                                    removeFromWishlist={removeFromWishlist} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default memo(WishlistContent);