import React, {memo, useState, useEffect} from "react";
import withHeaderAndFooter from "../../../with-header-and-footer-hoc";
import "./product-details-page-body.css";
import DetailsPageContent from "../details-page-content";
import SimilarProducts from "../similar-products";
import CartHeader from "../../../cart-page/cart-header";
import {useParams, useHistory} from "react-router-dom";
import Api from "../../../../services";
import {useStore} from "../../../../context";

const ProductDetailsPageBody = () => {
    const{ slug } = useParams();
    const [product, setProduct] = useState({});
    const [similarProduct, setSimilarProduct] = useState({});
    const history = useHistory();
    const { getLang } = useStore();

   
    useEffect(()=>{
        Api.products.getProductDetails({slug}).then(response => {
            window.scrollTo(0, 0)
            setProduct(response.data)
        });
    }, [slug]);

    useEffect(()=>{
        Api.products.getSimilarProducts({slug}).then(response => {
            setSimilarProduct(response.data)
        });
    }, [slug]);

    const sizesHandler = (e) => {
        history.push(`${getLang()}/product/${e.target.value}`);
    }

    return(
        <div className="product_details_page_body">
            {Object.keys(product).length > 0 &&
                <>
                    <DetailsPageContent sizesHandler={sizesHandler} product={product}/>
                    <SimilarProducts imgUrl={Api.imgUrl} products={similarProduct} setProduct={setProduct} />
                </>}
        </div>
    );
}
export default withHeaderAndFooter(memo(ProductDetailsPageBody));