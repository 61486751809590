import BaseRequestService from "./base-request-service";

class products extends BaseRequestService {
    getCategories = () => this.getRequest('/api/product/categories')
    getCollections = () => this.getRequest('/api/product/collections')
    getBanners = () => this.getRequest('/api/product/banners')
    getProducts = query => this.getRequest('/api/product', query)
    getProductsHome = query => this.getRequest('/api/product/home', query)
    getProductDetails = query => this.getRequest('/api/product/details', query)
    getSimilarProducts = query => this.getRequest('/api/product/similar', query)
    postSubscribeForProduct = query => this.postRequest('/api/product/details/subscribe', query)
    getLabels = () => this.getRequest('/api/product/labels');

    search = (query) => this.getRequest(`/api/site/search`, query)
}

export default new products();