import React, { Component } from 'react';
import "./with-header-and-footer-hoc.css";
import NavigationBar from "../navigation-bar";
import Footer from "../footer";

export default function withHeaderAndFooter(WrappedComponent, noWhite) {
    return class extends Component {
        render() {
            return (
                <div className="page_container">
                    {noWhite ? <div className={"home"}>
                        <NavigationBar />
                    </div> : <NavigationBar />}
                    <div className="page_content">
                        <WrappedComponent {...this.props} />
                        <Footer />
                    </div>
                </div>
            );
        };
    };
};
