import React, { useState } from 'react'
import { useStore } from '../../../context';
import './styles.scss'

const ProductSection = ({ title, state, setActiveProduct, activeProduct, error }) => {


    return (
        <div className="input-wrapp product">
            <span className='subtitle'>{title}</span>
            <div className="input-box">
                <span className={`${error ? 'error' : ''}`}>
                    Something went wrong
                </span>
                {state?.map((el, i) =>
                    <div
                        onClick={() => setActiveProduct(st => st.includes(el.id) ? st.filter(e => e !== el.id) : [...st, el.id])}
                        className={`check-button   ${activeProduct.includes(el.id) ? 'active' : ''}`}>{el.title}</div>
                )}
            </div>
        </div >
        
    )
}

export default ProductSection; 