import React from 'react';
import WithHeader from '../../components/with-header-and-footer-hoc'
import ford from '../../assets/ford.jpg';
import './style.scss'
import Portfolio from '../../pages/portfolio'

const PersonalPage = () => {


    return (
        <main className='personal_page'>
            <div className="main_image">
                <img alt="Braind" src={ford} alt="img" />
                <h3>Text</h3>
            </div>
            <div className="person_info">
                <div className="content">
                    <h2>Name Surname</h2>
                    {Array(4).fill().map(() =>
                        <span>info <span>12</span></span>
                    )
                    }
                </div>
            </div>
            <Portfolio />
        </main >
    );
};

export default WithHeader(PersonalPage, true);