import React, { useState } from "react";
import withHeaderAndFooter from "../../with-header-and-footer-hoc";
import "./shop-page-container.scss";
import ShopPageSlider from "../shop-header-slider/shop-page-slider";
import ShopPageGrid from "../shop-page-grid";
import ItemsSlider from "../product-slider";

const ShopPageBody = () => {

    const [categoryId, setCategoryId] = useState(null);
    const [sort, setSort] = useState(JSON.stringify({}));

    return (
        <div className="shop_page_container">
            <ShopPageSlider />
            <div className="shop_page_wrpper">
                <ShopPageGrid
                    categoryId={categoryId}
                    sort={sort}
                />
                <ItemsSlider />
            </div>
        </div>
    );
}
export default withHeaderAndFooter(ShopPageBody, true);