import React, { useEffect, useRef, memo } from 'react';

const OutsideClick = ({ children, onClick = null, action, stopPropagation, className = '', mousedown }) => {
  const wrap = useRef()

  const handleClick = e => {
    if (wrap.current && !wrap.current.contains(e.target)) {
      if (stopPropagation) {
        e.stopPropagation()
      }
      action && action();
    }
  }

  useEffect(() => {
    document.addEventListener(mousedown ? 'mousedown' : "click", handleClick)
    return () => {
      document.removeEventListener(mousedown ? 'mousedown' : "click", handleClick)
    }
  }, [action]);

  return (
    <div onClick={onClick} ref={wrap} className={className} >
      {children}
    </div>
  );
};

export default memo(OutsideClick);