const developmentUrl = {
  apiBaseUrl: "https://content.braind.am",
  imagesBaseUrl: "https://content.braind.am/storage/",
  defaultLanguage: "en"
}
const productionUrl = {
  apiBaseUrl: "https://content.braind.am",
  imagesBaseUrl: "https://content.braind.am/storage/",
  defaultLanguage: "en"
}
export default process.env.NODE_ENV === 'development' ? developmentUrl : productionUrl