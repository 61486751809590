import React, { useState, useEffect } from 'react';
import WithHeader from '../../components/with-header-and-footer-hoc'
import { useStore } from '../../context';
import Api from '../../services';
import './style.scss'

const ServicesPage = () => {
    const [data, setData] = useState();
    const [pageData, setPageData] = useState();

    const { getLang } = useStore()
    useEffect(() => {
        Api.getData.getServicesList()
            .then(res => setData(res?.data.items))
        Api.getData.getServicesContent()
            .then(res => setPageData(res?.data?.item))
    }, []);
    return (
        <main className='services-page'>
            <div className='wrapp-container'>
                <div className='page-info'>
                    <h1 data-aos="fade-up" data-aos-delay={300} className='page_title'>
                        {pageData?.title}
                    </h1>
                    <div data-aos="fade-up" data-aos-delay={400} className='services-text main' dangerouslySetInnerHTML={{ __html: pageData?.text }} />
                </div>
                <div className="services-content">
                    {data?.map((item, index) =>
                        <div data-aos="fade-up" className="info-block">
                            <h2 data-aos="fade-up" data-aos-delay={`${index + 3}00`} data-aos-easing="ease" className='page_title'>
                                {item.title}
                            </h2>
                            <p data-aos="fade-up" data-aos-delay={`${index + 4}00`} data-aos-easing="ease" className='services-text'>
                                {item.short_description}
                            </p>
                            <a data-aos="fade-up" aria-label="services" data-aos-delay={`${index + 5}00`} data-aos-easing="ease" href={`${getLang()}/service-details/${item.slug}`} className="services-link">
                                learn more</a>
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
};

export default WithHeader(ServicesPage);