import products from './products-request-service';
import wishlist from './wishlist-service';
import cart from './cart-service';
import config from '../config';
import contact from './contact-us-service';
import navigation from './navigation-service';
import text_page from './delivery-service';
import getData from './get-data';
import portfolio from './portfolio'

export default {
    text_page,
    cart,
    products,
    navigation,
    wishlist,
    getData,
    contact,
    portfolio,
    imgUrl: config.imagesBaseUrl
}