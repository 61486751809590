import React, { useState } from 'react'
import './styles.scss'
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useStore } from '../../../context';
import hy from "date-fns/locale/hy";
import ru from "date-fns/locale/ru";
registerLocale("hy", hy);
registerLocale("ru", ru);


const DateSection = ({ title, startDate, setStartDate, startTime, setStartTime }) => {

    const dateStart = moment(startDate).format('DD.MM.YYYY');
    const timeStart = moment(startTime).format('H:mm');
    const [openCalendar, setOpenCalendar] = useState(false);
    const [openTimer, setOpenTimer] = useState(false);
    const { tr, getLang } = useStore();
    let dateLanguage = getLang().substring(1);

    const ExampleCustomInput = ({ onClick, val, className }) => (
        <span className={`example-custom-input ${className}`} onClick={onClick}>
            {val}
        </span>
    );
    return (
        <div className="input-wrapp date">
            <span className="subtitle">{title}</span>
            <div className='inputs-wrapper'>
                <div className={`input-box ${openCalendar ? 'open' : ''}`}>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        locale = {`${dateLanguage}`}
                        minDate={new Date()}
                        selected={startDate}
                        onCalendarOpen={() => setOpenCalendar(true)}
                        onCalendarClose={() => setOpenCalendar(false)}
                        dateFormatCalendar="MMMM"
                        customInput={<ExampleCustomInput val={`${tr.date} ${startDate && `: ${dateStart}`}`} />}
                        onChange={date => setStartDate(date)}
                    />
                </div>
                <div className={`input-box ${openTimer ? 'open' : ''}`}>
                    <DatePicker
                        selected={startTime}
                        onCalendarOpen={() => setOpenTimer(true)}
                        onCalendarClose={() => setOpenTimer(false)}
                        showTimeSelect
                        showTimeSelectOnly
                        customInput={<ExampleCustomInput val={`${tr.time} ${startTime && `: ${timeStart}`}`} className='time' />}
                        timeIntervals={60}
                        timeCaption="Time"
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        onChange={date => setStartTime(date)}
                    />
                </div>
            </div>
        </div>
    )
}

export default DateSection; 