import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import './style.scss';
import { useStore } from '../../context';

const ProjectStart = () => {
    const [bottom, setBottom] = useState()
    const { startProjectBottom, tr, getLang } = useStore();

    const [tab, setTab] = useState()
    const servicesPage = ['service-details']
    const briefPage = ['brief']
    const { pathname } = useLocation()
    window.addEventListener('scroll', function () {
        if ((window.innerHeight + window.scrollY + 232) >= document.body.offsetHeight && window.innerWidth < 768) {
            setBottom(true)
        }
        else if ((window.innerHeight + window.scrollY + 209) >= document.body.offsetHeight && window.innerWidth >= 768) {
            setTab(true)

        }
        else {
            setBottom(false)
            setTab(false)
        }

    });

    const buttonDisplay = pathname.includes(servicesPage) || pathname.includes(briefPage)
    return (
        <>
            { bottom ?
                <div  style={{ position: "absolute", top: `${document.body.offsetHeight - 500}px` }} className={`start_project
                 ${buttonDisplay ? 'none' : ''} 
                 `}>
                    <Link to={`${getLang()}/brief`}>{tr.start}</Link>
                </div>
                :
                tab
                    ?
                    <div  style={{ position: "absolute", top: `${document.body.offsetHeight - 300}px` }} className={`start_project  ${buttonDisplay ? 'none' : ''}  `}>
                        <Link to={`${getLang()}/brief`}>{tr.start}</Link>
                    </div>
                    :
                    <div  className={`start_project  ${buttonDisplay ? 'none' : ''}   ${startProjectBottom ? '' : 'active'}`}>
                        <Link to={`${getLang()}/brief`}>{tr.start} </Link>
                    </div>
            }
        </>
    )
}

export default ProjectStart;