import React from 'react'
import './styles.scss'

const LanguageSection = ({ title, state, setActiveProduct, activeProduct, error }) => {


    return (
        <div className="input-wrapp product">
            <span className='subtitle'>{title}</span>
            <div className="input-box">
                <span className={`${error ? 'error' : ''}`}>
                    Something went wrong
                </span>
                {state?.map((el, i) =>
                    <div
                        onClick={() => setActiveProduct(st => st.includes(el.val) ? st.filter(e => e !== el.val) : [...st, el.val])}
                        className={`check-button ${activeProduct.includes(el.val) ? 'active' : ''}`}>{el.text}</div>
                )}
            </div>
        </div >
        
    )
}

export default LanguageSection; 