import React, { useEffect, useState } from 'react';
import WithHeader from '../../components/with-header-and-footer-hoc'
import Api from '../../services';
import './style.scss'
import { Link } from 'react-router-dom';
import { useStore } from '../../context';

const About = () => {

    const [data, setData] = useState()
    const [galleryData, setGalleryData] = useState()
    const [jobsData, setJobsData] = useState()
    const { getLang } = useStore();
    const [teamData, setTeamData] = useState()
    const [partnersData, setPartnersData] = useState()

    useEffect(() => {
        Api.getData.getAboutUsContent()
            .then(res => setData(res?.data?.item))
        Api.getData.getAboutUsImages()
            .then(res => setGalleryData(res?.data?.item))
        Api.getData.getJobs()
            .then(res => setJobsData(res?.data?.items))
        Api.getData.getTeam()
            .then(res => setTeamData(res?.data?.item))
        Api.getData.getPartners()
            .then(res => setPartnersData(res?.data?.items))

    }, [])

    return (
        <main className='about_page'>
            <div className='company'>
                <span className='page_title' data-aos='fade-up' data-aos-delay={300}>{data?.title}</span>
                <div className='desc' data-aos='fade-up' data-aos-delay={400} dangerouslySetInnerHTML={{ __html: data?.text }} />
                <div className='grid-panel' data-aos='fade-up' data-aos-delay={450} >
                    {galleryData?.map(el =>
                        <img alt="company" alt="company" src={Api.imgUrl + el.image} alt='' />
                    )}
                </div>
            </div>

            <div className='team'>
                <span className='page_title' data-aos='fade-up' data-aos-delay={550}>{data?.members_title}</span>
                <div className='grid-panel'>
                    {teamData?.map(el =>
                        <div  data-aos='fade-up' data-aos-delay={650} >
                            <div className='image-wrapper'>
                                <img alt="team" src={Api.imgUrl + el.image} alt='' />
                            </div>
                            <span data-aos='fade-up' data-aos-delay={700}>{el.name} {el.surname && el.surname}</span>
                            <span data-aos='fade-up' data-aos-delay={750}>{el.position}</span>
                        </div>
                    )}
                </div>
            </div>

            <div className='join'>
                <span className='page_title' data-aos='fade-up' data-aos-delay={950}>{data?.jobs_title}</span>
                <div className='desc' data-aos='fade-up' data-aos-delay={1050} dangerouslySetInnerHTML={{ __html: data?.jobs_text }} />
                <div className='grid-panel'>
                    {jobsData?.map(el => <Link to={`/jobs/${el.slug}`} data-aos='fade-up' data-aos-delay={1200}>{el.position}</Link>)}
                </div>
            </div>

            <div className="clients">
                <span className='page_title' data-aos='fade-up' data-aos-delay={1500} >{data?.partners_title}</span>
                <div className='grid-panel'>
                    {partnersData?.map(el =>
                        <img alt="Braind product" src={Api.imgUrl + el.image} data-aos='fade-up' data-aos='fade-up' data-aos-delay={1700} alt='' />
                    )}
                </div>
            </div>

        </main >
    );
};

export default WithHeader(About);