import React, { useState, memo, useEffect } from "react";
import "./shop-page-slider.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Api from '../../../services';

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    verticalSwiping: false,
    vertical: true,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
    swipeToSlide: false,
    arrows: false,
    pauseOnHover: true,
    cssEase: 'linear',
    touchThreshold: 100,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                fade: false,
                autoplay: false,
                vertical: false,
                horizontal: true,
                swipeToSlide: true,
                speed: 600,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                dots: false,
                fade: false,
                vertical: false,
                horizontal: true,
                swipeToSlide: true,
                speed: 600,
                infinite: true,
            }
        }
    ]
};




const ShopPageSlider = () => {
    const [data, setData] = useState()
    useEffect(() => {
        Api.getData.getSlider()
            .then(res => setData(res?.data?.items))
    }, [])

    return (
        <section className='main-slider-section' data-aos="fade-down" data-aos-delay={600} >
            <Slider {...settings}>
                {data?.map(item =>
                    <div className="container_wrapp">
                        <div className='slider-wrapper' >
                            <div className="main-side">
                                <img alt="Braind Shop" src={Api.imgUrl + item?.image} alt='next-stack' />
                                <img alt="Braind Shop" src={Api.imgUrl + item?.mobile_view_image} alt='next-stack' />
                                <div className='text-wrapp'>
                                    <span data-aos='fade-up' data-aos-delay={600} dangerouslySetInnerHTML={{ __html: item?.title }} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
        </section >
    );
}

export default memo(ShopPageSlider);