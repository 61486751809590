import React, { useState, useEffect } from 'react';
import './style.scss';
import withHeaderAndFooter from '../with-header-and-footer-hoc';
import Api from '../../services'
import { useStore } from '../../context';

const Contact = () => {
    const [content, setContent] = useState();
    const { tr } = useStore();

    useEffect(() => {
        Api.contact.getContactsPage()
            .then(res => {
                setContent(res?.data.items)
            })
    }, []);

    return (
        <div className="contact_layout">
            {content?.map(el => < div className="contact_cnt" >
                <h3 data-aos='fade-up' data-aos-delay={300} className="title">{el?.title}</h3>
                <div className="contacts ">
                    {el?.phone && <div className="call_us cntc">
                        <span data-aos='fade-up' data-aos-delay={600}>{tr.callUs}</span>
                        <a data-aos='fade-up' data-aos-delay={900} className="tel" href={`tel:${el?.phone}`}>{el?.phone}</a>
                    </div>}
                    {el?.email && <div className="write_us cntc">
                        <span data-aos='fade-up' data-aos-delay={600}>{tr.writeUs}</span>
                        <a data-aos='fade-up' data-aos-delay={900} className="mail" href={`mailto:${el?.email}`}>{el?.email}</a>
                    </div>}
                    {el?.address && <div className="address cntc">
                        <span data-aos='fade-up' data-aos-delay={1200}>{tr.address}</span>
                        <span data-aos='fade-up' data-aos-delay={1500}>{el?.address}</span>
                    </div>}
                </div>
                {!!el.map_url && <div data-aos='fade-up' data-aos-delay={800} className="map" dangerouslySetInnerHTML={{ __html: el?.map_url }} />}
            </div>)
            }
        </div >
    )
}

export default withHeaderAndFooter(Contact);