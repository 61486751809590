import React, { memo } from 'react';
import { ReactComponent as fb } from './icons/fb.svg';
import { ReactComponent as insta } from './icons/insta.svg';
import { ReactComponent as linkedin } from './icons/in.svg';
import { ReactComponent as be } from './icons/be.svg';
import { ReactComponent as twiter } from './icons/twiter.svg';
import { ReactComponent as m } from './icons/m.svg';
import { ReactComponent as attach } from './icons/attach.svg';
import { ReactComponent as deleteFile } from './icons/deleteFile.svg';
import { ReactComponent as round } from './icons/round.svg';
import { ReactComponent as search } from './icons/search.svg';
import { ReactComponent as blackheartWithItems } from './icons/favorite_black_with_item.svg';
import { ReactComponent as blackheart } from './icons/favorite_gray_filled.svg';
import { ReactComponent as bagcart } from './icons/cartbag.svg';
import { ReactComponent as right_arrow } from './icons/right_arrow.svg';
import { ReactComponent as close } from './icons/close.svg';
import { ReactComponent as done } from './icons/done.svg';


const components = {
  deleteFile,
  right_arrow,
  twiter,
  attach,
  fb,
  linkedin,
  insta,
  be,
  round,
  m,
  search,
  blackheartWithItems,
  blackheart,
  bagcart,
  close,
  done,
}
const Icon = ({ id, w: width, h: height, m: margin, className, onClick = null, htmlFor, ...rest }) => {

  const Component = components[id];
  const wP = width ? { width } : null;
  const hP = height ? { height } : null;
  const mP = margin ? { margin } : null;

  const styleProp = wP || hP || mP ? { style: { ...wP, ...hP, ...mP } } : null;

  const htmlForProp = htmlFor ? { htmlFor } : null;

  return (
    Component
      ? (
        <Component
          className={className || undefined}
          onClick={onClick}
          {...styleProp}
          {...htmlForProp}
          {...rest}
        />
      ) : null
  );
};

export default memo(Icon);