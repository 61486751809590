import { useRef, useCallback } from 'react';

export const useDebounce = (cb, time) => {
  const debounce = useRef(null);

  return useCallback((...rest) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      cb(rest);
      debounce.current = null;
    }, time);
  }, [cb, time]);
};
