import React, { useState, memo, useEffect } from "react";
import "./style.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { ProductsBuilder } from '../../../builders';
import { ProductsPartial, CollectionsPartial, BannersPartial } from './_partials/index';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

const LayoutGrid = props => {
    const collections = useSelector(store => store.collections)
    const banners = useSelector(store => store.banners)
    const [container, setContainer] = useState([]);
    const { categoryId, sort } = props;
    const [hasMore, setHasMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [bannerEvery, setBannerEvery] = useState(10);
    const itemsPerLoad = 10;

    const resizeHandler = width => {
        switch (true) {
            case width > 1350:
                setBannerEvery(10);
                break;
            case width <= 1350 && width >= 991:
                setBannerEvery(8);
                break;
            case width <= 991:
                setBannerEvery(6);
                break;
        }
    }

    const containerHandler = (data) => {
        setContainer(container => [
            ...container,
            ...data
        ]);
    };

    useEffect(() => {
        const checkSize = () => {
            resizeHandler(window.innerWidth)
        }
        window.addEventListener('resize', checkSize)
        return () => {
            window.removeEventListener('resize', checkSize)
        }
    }, []);

    const reloadItems = () => {
        setContainer([]);
        setPageNumber(1);
        setHasMore(true);
    }

    useEffect(() => {
        if (categoryId !== null) {
            reloadItems();
        }
    }, [categoryId]);

    useEffect(() => {
        if (container.length > 0) {
            reloadItems();
        }
    }, [sort])

    const loadMore = (page) => {
        setHasMore(false);
        const filter = JSON.parse(sort);

        const prod = new ProductsBuilder()
            .itemsCount(itemsPerLoad)
            .page(pageNumber);

        if (filter.new) {
            prod.orderByNewest(filter.new);
        }

        if (filter.price) {
            prod.orderByPrice(filter.price);
        }

        if (filter.sale) {
            prod.orderBySale(filter.sale);
        }

        if (filter.label) {
            prod.orderByLabel(filter.label);
        }

        if (Number(categoryId) > 0) {
            prod.setCategories([categoryId]);
        }

        prod.get().then(response => {
            const item = response?.data;
            containerHandler(item.data);
            setPageNumber(Number(item.current_page) + 1);
            if (Number(item.current_page) >= Number(item.last_page)) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    useEffect(() => {
        AOS.init();
        if (collections) {
            resizeHandler(window.innerWidth);
            setHasMore(true);
        }
    }, [collections]);

    return (
        <InfiniteScroll className="shop-grid-container"
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            threshold={250}>
            <div className='shop-grid-content'>
                {container.map((item, index) => {
                    if (index > 0 && ((index + 1) % bannerEvery) === 0) {
                        const bannerIndex = ((index + 1) / bannerEvery) - 1;
                        return (
                            <>
                                <ProductsPartial item={item} />
                                {banners[bannerIndex]
                                    && <BannersPartial data={banners[bannerIndex]} />}
                            </>
                        );
                    }
                    return <ProductsPartial item={item} />;
                })}
            </div>
        </InfiniteScroll>
    );
}

export default memo(LayoutGrid);