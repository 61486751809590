import React from 'react';
import './style.scss';

const Radio = ({ text, value, onChange }) => {
    return (
        <label class="container_input">
            {text}
            <input checked={value} onChange={onChange} type="radio" />
            <span class="checkmark" />
        </label>
    )
}

export default Radio;