import React, { useEffect, useState } from 'react';
import WithHeader from '../../components/with-header-and-footer-hoc'
import Icon from '../../icon';
import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img4 from './images/4.png'
import Api from '../../services';
import './style.scss'
import { Link, useParams } from 'react-router-dom';
import { useStore } from '../../context';
import { FacebookShareButton, VKShareButton, TwitterIcon, FacebookIcon, PinterestIcon, TwitterShareButton, PinterestShareButton, VKIcon } from "react-share";
const images = [
    {
        url: img1
    },
    {
        url: img2
    },
    {
        url: img3
    },
    {
        url: img4
    },
]
const PortfolioDetails = () => {
    const { slug } = useParams()
    const [data, setData] = useState()
    const { getLang, tr } = useStore();


    useEffect(() => {
        Api.getData.getPortfolioDetails(slug)
            .then(res => setData(res?.data))
    }, [slug])
    return (
        <main className='portfolio_detail'>
            <div className="content">
                <div className="jacobs">
                    <div className="left_side">
                        <div>
                            <span data-aos='fade-up' data-aos-delay={300}>{tr.client}</span>
                            <span data-aos='fade-up' data-aos-delay={400}>{data?.item?.client}</span>
                        </div>
                        <div>
                            <span data-aos='fade-up' data-aos-delay={500}>{tr.country}</span>
                            <span data-aos='fade-up' data-aos-delay={600}>{data?.item?.country}</span>
                        </div>
                        <div>
                            <span data-aos='fade-up' data-aos-delay={700}>{tr.year}</span>
                            <span data-aos='fade-up' data-aos-delay={800}>{data?.item?.year}</span>
                        </div>
                    </div>
                    <div className='mid'>
                        <h3 data-aos='fade-up' data-aos-delay={700}>{data?.item?.title}</h3>
                        <p data-aos='fade-up' data-aos-delay={800} dangerouslySetInnerHTML={{ __html: data?.item?.text1 }} />
                    </div>
                    <div className="right_side">
                        {data?.item?.project_members.map((el, i) =>
                            < div >
                                <span data-aos='fade-up' data-aos-delay={200 * i}>{el.position}</span>
                                <span data-aos='fade-up' data-aos-delay={300 * i} className="name">{el.name}</span>
                            </div>)}
                    </div>
                </div>
                <div className="social_icons">
                    <span data-aos='fade-up' data-aos-delay={700}>
                        {tr.shareWithFriends}
                    </span>
                    <div className="icons">
                        <FacebookShareButton
                            data-aos-delay={1000}
                            data-aos='fade-up'
                            size={40} round={true}
                            url={window.location.href}
                        >
                            <FacebookIcon />
                        </FacebookShareButton>
                        <VKShareButton
                            size={40}
                            url={window.location.href}
                            data-aos='fade-up'
                            data-aos-delay={1000}
                        >
                            <VKIcon />
                        </VKShareButton>
                        <PinterestShareButton
                            media={window.location.href}
                            size={40}
                            data-aos='fade-up'
                            url={window.location.href}
                            data-aos-delay={1000}
                        >
                            <PinterestIcon />
                        </PinterestShareButton>
                        <TwitterShareButton
                            size={40}
                            url={window.location.href}
                            data-aos='fade-up'
                            data-aos-delay={1000}>
                            <TwitterIcon />
                        </TwitterShareButton>

                    </div>
                </div>
                <div className="images ">
                    {data?.item?.portfolio_images.map((e, i) =>
                        <>
                            <img alt="portfolio" key={e.id} data-aos='fade-up' data-aos-delay={200 * i} src={Api.imgUrl + e.image} />
                            {!!e.text && <span data-aos='fade-up' dangerouslySetInnerHTML={{ __html: e.text }} />}
                        </>

                    )}
                </div>
                <div className="related_works">
                    <h3 data-aos='fade-up' data-aos-delay={500}>{tr.relatedProjects}</h3>
                    <div className='grid_items'>
                        {data?.related_works?.map((el, i) =>
                            <Link to={`${getLang()}/portfolio/${el.slug}`} data-aos='fade-up' data-aos-delay={250 * i} key={i}>
                                <div className="image-box"><img alt="Braind" src={Api.imgUrl + el?.thumbnail} /></div>
                                <span>{el?.title}</span>
                            </Link>
                        )}
                    </div>
                    <Link to={`${getLang()}/portfolio`} className="btn">{tr.allProjects}</ Link>
                </div>
            </div>
        </main >
    );
};

export default WithHeader(PortfolioDetails);