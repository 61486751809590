import React, { memo, useState } from "react";
import "./payment-form.css";
import banklogo from "../../../assets/banklogo.png";
import TextField from "@material-ui/core/TextField";
import secureicon from "../../../assets/secureicon.png";
import Api from '../../../services';
import { useStore } from "../../../context";
import { useHistory } from "react-router-dom";

const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const PaymentForm = props => {

    const { data } = props;
    const [nameInput, setNameInput] = useState('');
    const [sureNameInput, setSureNameInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [donateInput, setDonateInput] = useState('');
    const [phoneInput, setPhoneInput] = useState('');
    const [note, setNote] = useState('');
    const [receiveNews, setReceiveNews] = useState(false);
    const [receiveTerms, setReceiveTerms] = useState(false);
    const [nameInputError, setNameInputError] = useState(false);
    const [emailInputError, setEmailInputError] = useState(false);
    const [donateInputError, setDonateInputError] = useState(false);
    const [phoneInputError, setPhoneInputError] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const { bag, getLang, tr } = useStore();
    const history = useHistory();

    const validate = () => {
        const emailError = (!emailInput.length || !validateEmail(emailInput));
        const phoneError = (data?.calculate?.total > 0 && !phoneInput.length);
        const termsError = !receiveTerms;
        const nameError = !nameInput.length;
        setEmailInputError(emailError);
        setPhoneInputError(phoneError);
        setTermsError(termsError);
        setNameInputError(nameError);

        return !emailError && !phoneError && !termsError && !nameError;
    }

    const getForm = () => {
        const form = new FormData();
        form.append('client_name', nameInput);
        form.append('client_surname', sureNameInput);
        form.append('client_phone', phoneInput);
        form.append('client_email', emailInput);
        form.append('client_message', note);
        form.append('subscribe', String(Number(receiveNews)));

        bag.forEach((item, index) => {
            form.append(`items[${index}][stock_id]`, item.id);
            form.append(`items[${index}][quantity]`, item.quantity);
        })

        return form;
    };

    const donateInputHandler = (data) => {
        const regex = new RegExp("[0-9]");
        if (regex.test(data) && data > 0) {
            setDonateInput(data)
        } else if (!data.length) {
            setDonateInput('')
        }
    }

    const clearData = () => {
        setNameInput('');
        setEmailInput('');
        setPhoneInput('');
        setSureNameInput('');
        setNote('');
        setDonateInput('')
    }

    const paymentSubmit = () => {
        if (validate()) {
            const form = getForm();
            Api.cart.checkout(form).then(response => {
                clearData();
                window.location.href = response?.data?.content?.payment_url;
            }).catch(reason => console.log(reason.message));
        }
    };

    const getDownloadLink = () => {
        if (validate()) {
            const form = getForm();
            Api.cart.download(form).then(response => {
                clearData();
                history.push(`${getLang()}/confirm`);
            }).catch(reason => console.log(reason.message));
        }
    };

    const donate = () => {
        setDonateInputError(!donateInput.length);
        if (validate()) {
            const form = getForm();
            form.append('donate', donateInput);

            Api.cart.donate(form).then(response => {
                clearData();
                window.location.href = response?.data?.content?.payment_url;
            }).catch(reason => console.log(reason.message));
        }
    };

    return (
        <div className="payment_form">
            <div className="payment_form_content">
                <div className="payment_bank_logo">
                    <img alt="Bank" src={banklogo} />
                </div>
                <div className="payment_form_container">
                    <div className="payment_form_title">
                        {tr.personalDetails}
                    </div>
                    <div className="payment_form_section">
                        <div className="payment_form_fields left_field">
                            <form noValidate autoComplete="off">
                                <TextField label={`* ${tr.name}`}
                                    error={nameInputError}
                                    value={nameInput}
                                    onChange={e => setNameInput(e.target.value)} />
                            </form>
                        </div>
                        <div className="payment_form_fields right_field">
                            <form noValidate autoComplete="off">
                                <TextField label={tr.surname}
                                    value={sureNameInput}
                                    onChange={e => setSureNameInput(e.target.value)} />
                            </form>
                        </div>
                        <div className="payment_form_fields left_field">
                            <form noValidate autoComplete="off">
                                <TextField label={`${data?.calculate?.total > 0 ? '* ' : ''}${tr.phone}`}
                                    error={phoneInputError}
                                    value={phoneInput}
                                    type="text"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value === '' || /^[0-9\b]+$/.test(value)) {
                                            setPhoneInput(value)
                                        }
                                    }} />
                            </form>
                        </div>
                        <div className="payment_form_fields right_field">
                            <form noValidate autoComplete="off">
                                <TextField label={`* ${tr.email}`}
                                    error={emailInputError}
                                    value={emailInput}
                                    onChange={e => setEmailInput(e.target.value)} />
                            </form>
                        </div>
                        {data?.calculate?.total <= 0 && <div className="payment_form_fields left_field">
                            <form noValidate autoComplete="off">
                                <TextField label={tr.donate}
                                    error={donateInputError}
                                    value={donateInput}
                                    onChange={e => donateInputHandler(e.target.value)} />
                            </form>
                        </div>}

                        <div className="payment_form_fields payment_textarea">
                            <p>{tr.addNotes}</p>
                            <textarea name="" id="" cols="30" rows="4" value={note} onChange={e => setNote(e.target.value)} ></textarea>
                        </div>
                        <div className="payment_check_container">
                            <div className="payment_checkbox_container">
                                <label className="container">
                                    <input type="checkbox" checked={receiveTerms} onClick={() => setReceiveTerms(val => !val)} />
                                    <span className={`checkmark ${termsError ? 'error' : ''}`}></span>
                                    <div className="payment_check_label">
                                        <span>{tr.byClicking}</span> <a target="_blank" href="policy" className="term">{tr.termsAndConditions}</a>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="payment_check_container">
                            <div className="payment_checkbox_container">
                                <label className="container">
                                    <input type="checkbox" checked={receiveNews} onClick={() => setReceiveNews(val => !val)} />
                                    <span className="checkmark"></span>
                                    <div className="payment_check_label">
                                        {tr.receiveNews}
                                    </div>
                                </label>
                            </div>

                        </div>

                        {!!data.items && (
                            data.calculate.total > 0 ?
                                <div className="payment_button_container">
                                    <div className="page_button" onClick={paymentSubmit}>
                                        {tr.goToPayment}
                                    </div>
                                </div> :
                                <div className="payment_button_container">
                                    <div className="page_button" onClick={donate}>
                                        {tr.donateAndDownload}
                                    </div>
                                    <div className="donate_button" onClick={getDownloadLink}>
                                        {tr.getDownloadLink}
                                    </div>
                                </div>
                        )
                        }
                        <div className="secure_label">
                            <div className="secure_icon">
                                <img alt="secure" src={secureicon} />
                            </div>
                            <div className="secure_text">
                                {tr.buyingFromBraind}<br />
                                {tr.yourOrderWill}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(PaymentForm);