import React, { useState } from "react";
import withHeaderAndFooter from "../../with-header-and-footer-hoc";
import ShopGridHeader from '../../shop-layout/layout-header';
import LayoutGrid from "../layout-grid/layout-grid";
import "./style.scss";
import LayoutSlider from "../layout-slider/layout-slider";

const ShopPageBody1 = () => {

    const [categoryId, setCategoryId] = useState(null);
    const [sort, setSort] = useState(JSON.stringify({}));

    return (
        <div className="shop_page_container">
            <LayoutSlider />
            <ShopGridHeader
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                sort={sort}
                setSort={setSort}
            />
            <LayoutGrid
                categoryId={categoryId}
                sort={sort}
            />
        </div>
    );
}
export default withHeaderAndFooter(ShopPageBody1);