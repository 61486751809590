import React, { memo, useEffect, useState } from "react";
import "./navigation-bar.scss";
import { HamburgerButton } from "react-hamburger-button";
import logo2 from "../../assets/logo2.png";
import { useSelector } from "react-redux";
import Api from '../../services';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useStore } from "../../context";
import Icon from '../../icon';




const NavigationBar = () => {
    const navigation = useSelector(store => store.navigation);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const { getLang, bag, wishlist, tr } = useStore();
    const { pathname } = useLocation();

    const wishlistPage = ['/wishlist']
    const cartPage = ['/cart']
    const wishlistHandler = () => {
        history.push(`${getLang()}/wishlist`);
    }
    const cartHeader = () => {
        history.push(`${getLang()}/cart`);
    }

    const [data, setData] = useState()
    const [services, setServices] = useState()
    useEffect(() => {
        Api.getData.getServicesList()
            .then(res => setServices(res?.data.items))
        Api.getData.getSpecialProjectList()
            .then(res => setData(res?.data?.items))
    }, [])

    const langChange = lang => {
        let oldUrl = window.location.href.split('/');
        const links = oldUrl[3].split('-');
        const locales = ['hy', 'ru', 'en'];

        switch (true) {
            case locales.includes(links[0]) && lang === 'en':
                oldUrl = oldUrl.filter((item, index) => index !== 3);
                break;
            case locales.includes(links[0]):
                links[0] = lang
                oldUrl[3] = links.join('/');
                break;
            case lang !== 'en':
                links.unshift(lang);
                oldUrl[3] = links.join('/');
        }

        const newUrl = oldUrl.join('/');
        window.location.replace(newUrl)
    }

    const handleClick = () => {
        document.body.classList.toggle('body-fix');
        setOpen(val => !val);
    }
    const closeMenue = () => {
        document.body.classList.remove('body-fix');
        setOpen(false);
    }
    const [top, setTop] = useState()

    window.addEventListener('scroll', function () {
        if (window.scrollY > window.innerHeight - 60) {
            setTop(true)
        }
        else if (window.scrollY >= 600 && window.innerWidth < 1024) {
            setTop(true)

        }
        else if (window.scrollY >= 340 && window.innerWidth < 768) {
            setTop(true)

        } else {
            setTop(false)
        }
    });

    return (
        <>
            <div className={`navigation_bar ${top ? 'bg' : ''} ${open ? 'active' : ''}`}>
                <a class="skip-link" href="#maincontent">Skip to main</a>

                <div className="navbar_cnt">
                    <a aria-label="home" href={`${getLang()}/`} onClick={closeMenue} >
                        <div className="nav_logo">
                        </div>
                    </a>
                    <ul className={`nav_menu`}>
                        <li><a className="nav_link" href={`${getLang()}/portfolio`}>{tr.portfolio} </a></li>
                        <li><a className="nav_link" href={`${getLang()}/about`}>{tr.company}</a></li>
                        <li><a className="nav_link" href={`${getLang()}/services`}>{tr.services}</a></li>
                        <li><a className="nav_link" href={`${getLang()}/shop`}>{tr.shop}</a></li>
                        <li> <span className="nav_link" >{tr.special_project}</span>
                            {!!data &&
                                <div className='link_popup'>
                                    {
                                        data?.map(el =>
                                            <a href={`${getLang()}/events/${el.slug}`}>{el?.title}</a >
                                        )
                                    }
                                </div>}
                        </li>
                        <li><a className="nav_link" href={`${getLang()}/contacts`}>{tr.contact}</a></li>
                        <li><Link aria-label='search' to={`${getLang()}/search`} className="search_icon" >
                            <Icon id='search' />
                        </Link>
                        </li>
                    </ul>
                    <div className="nav_last_child">
                        <div className="nav_lang_container">
                            <div className={`nav_lang_item ${localStorage.getItem("lang") === "en" ? "active-bold" : ""}`} onClick={() => { langChange('en') }}>
                                En
                                </div>
                            <div className={`nav_lang_item ${localStorage.getItem("lang") === "hy" ? "active-bold" : ""}`} onClick={() => { langChange('hy') }}>
                                Am
                             </div>
                            <div className={`nav_lang_item ${localStorage.getItem("lang") === "ru" ? "active-bold" : ""}`} onClick={() => { langChange('ru') }}>
                                Ru
                             </div>
                        </div>
                        <div className="shop_header_icons_container">
                            <div>
                                <div className="black_heart_container">
                                    <Icon id={wishlist.length > 0 ? 'blackheartWithItems' : 'blackheart'} onClick={wishlistHandler} />
                                </div>
                                <div className="shop_cart_container" onClick={() => { closeMenue(); cartHeader() }}>
                                    <Icon id='bagcart' className="cart_icon" />
                                    <span className={`bag_quanty ${bag.length > 0 ? 'active' : ''}`}> {tr.cart} ({bag.length})</span>
                                </div>
                            </div>
                            <Link aria-label='search' to={`${getLang()}/search`} className="search_icon" onClick={closeMenue} >
                                <Icon id='search' />
                            </Link>
                        </div>
                    </div>
                    <div className="hamburger_menu">
                        <HamburgerButton
                            open={open}
                            onClick={handleClick}
                            width={30}
                            height={16}
                            color='black'
                            animationDuration={0.5}
                        />
                    </div>
                </div >
            </div >
            <div className={open ? 'nav_content nav_active' : 'nav_content '}>
                <div className="nav_header">
                    <div className="nav_header_logo">
                        <img alt="Braind Shop" src={logo2} />
                    </div>

                </div>
                <div className="nav_pages_list_container">
                    <ul className={open ? 'nav_menu nav_nav_menu_active' : 'nav_menu nav_nav_menu_unactive'}>
                        <li style={{ transition: 'all 0.7s ease-in-out' }} ><a className="nav_link" href={`${getLang()}/portfolio`}>{tr.portfolio} </a></li>
                        <li style={{ transition: 'all 0.8s ease-in-out' }}><a className="nav_link" href={`${getLang()}/about`}>{tr.company}</a></li>
                        <li style={{ transition: 'all 0.9s ease-in-out' }}><a className="nav_link" href={`${getLang()}/services`}>{tr.services}</a></li>
                        <li style={{ transition: 'all 1s ease-in-out' }}><a className="nav_link" href={`${getLang()}/shop`}>{tr.shop}</a></li>
                        <li style={{ transition: 'all 1.1s ease-in-out' }}> <span className="nav_link" >{tr.special_project}</span>
                            {!!data &&
                                <ul><li style={{ transition: '1.2s ease-in-out' }} className=" nav_service_pages">
                                    {
                                        data?.map((el, i) =>
                                            <ul>
                                                <li style={{ transition: `${1.3 + (i / 20)}s ease-in-out` }}>
                                                    <a href={`${getLang()}/events/${el.slug}`}>{el?.title}</a >
                                                </li>
                                            </ul>
                                        )
                                    }
                                </li>
                                </ul>}
                        </li>
                        <li style={{ transition: '1.5s ease-in-out' }} > <a className="nav_link" href={`${getLang()}/contacts`}>{tr.contact}</a></li>
                    </ul>
                </div>
                <div className="nav_lang_container mobile">
                    <div className={`nav_lang_item ${localStorage.getItem("lang") === "en" ? "active-bold" : ""}`} onClick={() => { langChange('en') }}>
                        En
                                </div>
                    <div className={`nav_lang_item ${localStorage.getItem("lang") === "hy" ? "active-bold" : ""}`} onClick={() => { langChange('hy') }}>
                        Am
                             </div>
                    <div className={`nav_lang_item ${localStorage.getItem("lang") === "ru" ? "active-bold" : ""}`} onClick={() => { langChange('ru') }}>
                        Ru
                             </div>
                </div>
                <Link className="nav_phone_number" to='/brief' onClick={closeMenue}>
                    <span>START A PROJECT</span>
                </Link>
            </div >
        </>
    );
}

export default memo(NavigationBar);