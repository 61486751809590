import React, { useEffect } from "react";
import "./app.scss";
import '../../styles/global.scss'
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import ShopPageBody from "../shop-page/shop-page-body/shop-page-body";
import ProductDetailsPageBody from "../product/product-details/product-details-page-body/product-details-page-body";
import CartPageBody from "../cart-page/cart-page-body/cart-page-body";
import PaymentPageBody from "../payment-page/payment-page-body/payment-page-body";
import WishListPageBody from "../wishlist/wishlist-page-body/wishlist-page-body";
import CollectionPageBody from "../collection-page/collection-page-body/collection-page-body";
import ConfirmPage from "../confirm-page/confirm-page";
import PaymentDelivery from "../delivery";
import Api from '../../services';
import { useDispatch } from 'react-redux'
import PaymentMethods from "../payment-methods/methods";
import PrivacyPolicy from "../Privacy-Police/privacy-policy";
import About from '../../pages/about'
import ProjectStart from "../start-btn/start";
import Portfolio from "../../pages/portfolio";
import Contact from "../contacts";
import ServicesPage from "../../pages/services-page";
import ServiceDetails from "../../pages/service-details";
import ShopPageBody1 from '../../components/shop-layout/layout-body/layout-body';
import BlackFriday from "../../pages/black-friday";
import AOS from 'aos';
import PortfolioDetails from "../../pages/portfolio-details";
import JobDetails from "../../pages/job-details";
import SendBrief from "../../pages/send-brief";
import Search from "../../pages/search";
import PersonalPage from '../../pages/personal-page'

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);

  }, [pathname]);
  return null;
}
function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 500,
      offset: 100,
      easing: "ease"
    });
  }, []);



  useEffect(() => {
    Api.products.getCollections()
      .then(data => dispatch({
        type: 'COLLECTIONS',
        data: data?.data.items
      }))

  }, []);

  useEffect(() => {
    Api.products.getBanners()
      .then(data => dispatch({
        type: 'BANNERS',
        data: data?.data.items
      }))
  }, []);

  useEffect(() => {
    Api.contact.getContacts()
      .then(data => dispatch({
        type: 'CONTACTS',
        data: data?.data,
      }))
  }, []);

  useEffect(() => {
    Api.navigation.getNavigation()
      .then(data => dispatch({
        type: 'NAVIGATION',
        data: data?.data,
      }))
  }, []);

  useEffect(() => {
    Api.products.getLabels()
      .then(data => dispatch({
        type: 'LABELS',
        data: data?.data,
      }))
  }, []);

  useEffect(() => {
    locale === 'hy'
      ? document.body.classList.add('arm_font')
      : document.body.classList.remove('arm_font')
  }, []);

  const link = window.location.pathname.split('/')[1]
  const langs = ['en', 'ru', 'hy'];
  const locale = langs.includes(link) ? link : 'en';
  const base = '/:locale(en|ru|hy)?';

  return (
    <div className={`App ${locale}`}>
      <Router  >
        <ScrollToTop />
        <ProjectStart />
        <Switch>
          <Route path={`${base}/`} exact component={ShopPageBody} />
          <Route path={`${base}/product/:slug`} exact component={ProductDetailsPageBody} />
          <Route path={`${base}/about`} exact component={About} />
          <Route path={`${base}/events/:slug`} exact component={BlackFriday} />
          <Route path={`${base}/services`} exact component={ServicesPage} />
          <Route path={`${base}/service-details/:slug`} exact component={ServiceDetails} />
          <Route path={`${base}/portfolio`} exact component={Portfolio} />
          <Route path={`${base}/cart`} exact component={CartPageBody} />
          <Route path={`${base}/contacts`} exact component={Contact} />
          <Route path={`${base}/shop`} exact component={ShopPageBody1} />
          <Route path={`${base}/jobs/:slug`} exact component={JobDetails} />
          <Route path={`${base}/personal`} exact component={PersonalPage} />
          <Route path={`${base}/portfolio/:slug`} exact component={PortfolioDetails} />
          <Route path={`${base}/search`} exact component={Search} />
          <Route path={`${base}/payment`} exact component={PaymentPageBody} />
          <Route path={`${base}/wishlist`} exact component={WishListPageBody} />
          <Route path={`${base}/collection/:id`} exact component={() => <CollectionPageBody />} />
          <Route path={`${base}/banners/:id`} exact component={() => <CollectionPageBody banners />} />
          <Route path={`${base}/confirm`} exact component={ConfirmPage} />
          <Route path={`${base}/delivery`} exact component={PaymentDelivery} />
          <Route path={`${base}/methods`} exact component={PaymentMethods} />
          <Route path={`${base}/policy`} exact component={PrivacyPolicy} />
          <Route path={`${base}/brief`} exact component={SendBrief} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
