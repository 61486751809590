import React from 'react'
import './styles.scss'

const CustomInput = ({ error, text, required, id , type = 'text', val, onChange }) => {

    return (
        <label className={`custom-label ${error ? 'error' : ''}`}>
            <input value={val} onChange={onChange} required={required} placeholder=' ' type={type} className="inp" />
            <span className='placeholder' >{text} <span>{required && '*'}</span></span>
        </label>

    )
}

export default CustomInput;