import React, { memo, useEffect } from 'react';
import './text-page.css';
import Api from '../../services';

const TextPage = props => {
    const { content } = props;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="delivery-page-content">
                <div className="payment-text">{content?.items?.map(item =>
                    <div className="payment-flex">
                        <div className="payment-text-title">
                            <h2>{item.title}</h2>
                        </div>
                        <div className="payment-text-contnet" dangerouslySetInnerHTML={{ __html: item.text }}>
                        </div>
                    </div>
                )}


                </div>
            </div>
        </>
    )
}

export default memo(TextPage);