import React, { useState, memo, useEffect } from "react";
import "./style.scss";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Api from '../../../services';
import { useHistory } from "react-router-dom";
import { useStore } from "../../../context";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

        const ShopGridHeader = props => {
            const { categoryId, setCategoryId, sort, setSort } = props;
                const [categories, setCategories] = useState([]);
                const history = useHistory();
                const { bag, wishlist, getLang, tr } = useStore();
                const labels = useSelector(store => store.labels);
                const wishlistHandler = () => {
                    history.push(`${getLang()}/wishlist`);
                }
                const cartHeader = () => {
                    history.push(`${getLang()}/cart`);
                }
            
                useEffect(() => {
                    Api.products.getCategories()
                        .then(data => setCategories(data?.data?.items))
                }, []);


    return (
        <div className="shop_grid_header">
            <div className="shop_grid_header_content">
                <div className="shop_header_nav_container">
                    <div className='buttons_tab'>
                        <button onClick={() => setCategoryId(0)} className={categoryId == 0 || categoryId == null ? 'active' : ''}>
                            {tr.all}
                        </button>
                        {categories?.map((el, i) => <button onClick={() => setCategoryId(el.id)} className={categoryId == el.id ? 'active' : ''}>{el.name}</button>)}
                    </div>
                </div>
                <div className="shop_header_select_container category">
                    <FormControl>
                        <Select value={categories[categoryId]} onChange={(e) => setCategoryId(e.target.value)} displayEmpty>
                            <MenuItem>
                                <div className="shop_header_select_placeholder">
                                    <div className="shop_header_select_label category">
                                        {tr.all}
                                    </div>
                                </div>
                            </MenuItem>
                            {categories.map((el, i) =>
                                <MenuItem className="isopen-li" value={el.id}>
                                    <div className="shop_header_select_placeholder">
                                        <div className="shop_header_select_label categoty">
                                            {el.name}
                                        </div>
                                    </div>
                                </MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <div className="shop_header_select_container">
                    <FormControl>
                        <Select value={sort} onChange={(event) => setSort(event.target.value)} displayEmpty>
                            <MenuItem value={JSON.stringify({})}>
                                <div className="shop_header_select_placeholder">
                                    <div className="select_icon"> </div>
                                    <div className="shop_header_select_label">
                                        {tr.sortBy}
                                    </div>
                                </div>
                            </MenuItem>
                            {labels?.items?.map(item =>
                                <MenuItem className="isopen-li" value={JSON.stringify({ label: Number(item.id) })}>
                                    <div className="shop_header_select_placeholder">
                                        <div className="select_icon"> </div>
                                        <div className="shop_header_select_label">
                                            {item.name}
                                        </div>
                                    </div>
                                </MenuItem>)}
                            <MenuItem className="isopen-li" value={JSON.stringify({ sale: 'desc' })}>
                                <div className="shop_header_select_placeholder">
                                    <div className="select_icon"> </div>
                                    <div className="shop_header_select_label">
                                        {tr.filter.sale}
                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem className="isopen-li" value={JSON.stringify({ price: 'desc' })}>
                                <div className="shop_header_select_placeholder">
                                    <div className="select_icon"> </div>
                                    <div className="shop_header_select_label">
                                        {tr.filter.priceHighLow}
                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem className="isopen-li" value={JSON.stringify({ price: 'asc' })}>
                                <div className="shop_header_select_placeholder">
                                    <div className="select_icon"> </div>
                                    <div className="shop_header_select_label">
                                        {tr.filter.priceLowHigh}
                                    </div>
                                </div>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>

            </div>
        </div>
    );
};

export default memo(ShopGridHeader);