import React, { useEffect, useState } from 'react';
import WithHeader from '../../components/with-header-and-footer-hoc';
import './style.scss';
import Icon from '../../icon';
import Api from '../../services';
import { useParams } from 'react-router-dom';
import CustomInput from '../../components/input/input';
import ConfirmPopup from '../../components/confirm-popup';
import { useStore } from '../../context';


const JobDetail = () => {
    const { slug } = useParams()
    const [openPopup, setOpenPopup] = useState(false)
    const [hide, setHide] = useState()
    const [data, setData] = useState()
    const [file, setFile] = useState();
    const [fileError, setFileError] = useState();
    const [nameInput, setNameInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [surNameInput, setSurName] = useState('');
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [aboutPerson, setAboutPerson] = useState('')
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [text, setText] = useState('Your request sent');
    const { tr } = useStore();

    const togglePopup = () => {
        setOpenPopup(val => !val)
        document.body.classList.toggle('body-fix');
    }



    const getForm = () => {
        const form = new FormData();
        form.append('name', nameInput);
        form.append('email', emailInput);
        form.append('surname', surNameInput);
        form.append('phone', phone);
        { aboutPerson && form.append('message', aboutPerson); }
        form.append('job_id', data.id);
        { file && form.append('attachment', file); }
        return form;
    };


    const submitHandler = () => {
        const nameStatus = !nameInput;
        const fileStatus = !file;
        const phoneStatus = !phone;
        const emailStatus = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput));
        setNameError(nameStatus);
        setEmailError(emailStatus);
        setPhoneError(phoneStatus);
        setFileError(fileStatus)
        const form = getForm();
        Api.getData.getJobDetailsCv(form).then(() => {
            togglePopup()
            setNameInput('');
            setEmailInput('');
            setSurName('');
            setPhone('');
            setAboutPerson('');
            setFile(null)
        })
    }

    useEffect(() => {
        Api.getData.getJobDetails(slug)
            .then(res => setData(res?.data?.item))
    }, [slug])
    return (
        <section className='page__container job_detail'>
            <div className="content">
                <div className="title">
                    <h1 data-aos='fade-up' data-aos-delay={300}>{data?.position}</h1>
                    <span data-aos='fade-up' data-aos-delay={500} dangerouslySetInnerHTML={{ __html: data?.short_description }} />
                </div>
                {!!data?.description?.length && <div className="description">
                    <p data-aos='fade-up' data-aos-delay={900} className="text" dangerouslySetInnerHTML={{ __html: data?.description }} />
                </div>}
                {!!data?.responsibilities?.length && <div className="responsibilities">
                    <h3 data-aos='fade-up' data-aos-delay={950} className="subtitle">
                        {tr.required_skills}
                    </h3>
                    <span data-aos='fade-up' data-aos-delay={1200} dangerouslySetInnerHTML={{ __html: data?.qualifications }} />
                </div>}
                {!!data?.qualifications?.length && <div className="qualifications">
                    <h3 data-aos='fade-up' data-aos-delay={1100} className="subtitle">
                        {tr.benefit_pack}
                    </h3>
                    <span data-aos='fade-up' data-aos-delay={1000} dangerouslySetInnerHTML={{ __html: data?.responsibilities }} />
                </div>}
                <div>
                    <h3 className="subtitle" data-aos='fade-up' data-aos-delay={1300}>
                        {tr.about_portfolio}
                    </h3>
                    <div className="form_wrap">
                        <form data-aos-delay={1450} action="">
                            <div data-aos='fade-up' data-aos='fade-up' data-aos-delay={1400}>
                                <CustomInput error={nameError} onChange={e => setNameInput(e.target.value)}
                                    val={nameInput} text={tr.name} required />
                            </div>
                            <div data-aos='fade-up' data-aos='fade-up' data-aos-delay={1400}>
                                <CustomInput onChange={e => setSurName(e.target.value)}
                                    val={surNameInput} text={tr.surname} />
                            </div>
                            <div data-aos='fade-up' data-aos='fade-up' data-aos-delay={1400}>
                                <CustomInput error={emailError} onChange={e => setEmailInput(e.target.value)}
                                    val={emailInput} type={'email'} text={tr.email} required />
                            </div>
                            <div data-aos='fade-up' data-aos='fade-up' data-aos-delay={1400}>
                                <CustomInput error={phoneError} required onChange={(e) => {
                                    const { value } = e.target;
                                    if (value === '' || /^[0-9\b]+$/.test(value)) {
                                        setPhone(value)
                                    }
                                }}
                                    val={phone} type='text' text={tr.phone_number} /></div>

                            <div data-aos='fade-up' data-aos-delay={1450} className="text_area">
                                <textarea placeholder={tr.text} value={aboutPerson} data-aos='fade-up' className="inp" onChange={e => setAboutPerson(e.target.value)} />
                            </div>
                        </form>
                    </div>
                    <div className="doc_wrap">
                        <label className={`documents ${fileError ? "error" : ''}`}>
                            <div className={`attach ${fileError ? "error" : ''}`}>
                                <Icon id="attach" />
                            </div>
                            {file ? <span >{file?.name}</span>
                                : <span >{tr.attachFile}</span>}
                            <input key={file} type="file" name="file" onChange={(e) => setFile(e.target.files[0])} />
                        </label>
                        {!!file && <div className="resetFile" onClick={() => setFile(null)}><Icon id='deleteFile' /></div>}
                    </div>
                    <button onClick={submitHandler} data-aos='fade-up' data-aos-delay={1650} className="send_btn ">
                        {tr.send}
                    </button>
                </div>
                <ConfirmPopup title={text} openPopup={openPopup} togglePopup={() => togglePopup()} />
            </div>
        </section >
    );
};

export default WithHeader(JobDetail);
